<template>
  <section>
    <b-card>
      <div class="d-flex align-items-center my-2">
        <feather-icon
          icon="SettingsIcon"
          size="18"
        />
        <h4 class="mb-0 ml-50">
          Competition Settings:
        </h4>
      </div>
      <b-row>
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            LIMIT BETSLIP
          </div>
        </b-col>
        <b-col cols="2">
          <div>Current</div>
          <b-form-input
            id="type-number"
            :value="currentLimitBetSlip"
            readonly
          />
        </b-col>

        <b-col cols="5">
          <b-row>
            <b-col
              cols="7"
              class="col-deposit"
            >
              <div>Change</div>
              <b-form-input
                id="limit-value"
                v-model="amount"
                :formatter="formatNumber"
              />
            </b-col>

            <b-button
              variant="primary"
              :disabled="loadingLimitBetSlip"
              class="btn-set ml-2 mt-2"
              @click="handleApplyLimitBetslip"
            >
              <Spinner v-if="loadingLimitBetSlip" />
              <template v-else>
                Apply
              </template>
            </b-button>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            VOUCHER PRICE
          </div>
        </b-col>
        <b-col cols="2">
          <div>Current</div>
          <b-form-input
            id="voucher-price"
            :value="formatCurrencyBuiltInFunc(currentVoucherPrice)"
            readonly
          />
        </b-col>

        <b-col cols="5">
          <b-row>
            <b-col
              cols="7"
              class="col-deposit"
            >
              <div>Change</div>
              <b-form-input
                v-model="price"
                type="number"
                :no-wheel="true"
                :formatter="formatPrice"
              />
            </b-col>

            <b-button
              variant="primary"
              :disabled="loadingVoucherPrice"
              class="btn-set ml-2 mt-2"
              @click="handleApplyVoucherPrice"
            >
              <Spinner v-if="loadingVoucherPrice" />
              <template v-else>
                Apply
              </template>
            </b-button>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            VOUCHER LIMIT
          </div>
        </b-col>
        <b-col cols="2">
          <div>Current</div>
          <b-form-input
            id="voucher-limit"
            :value="currentLimitVoucher"
            readonly
          />
        </b-col>

        <b-col cols="5">
          <b-row>
            <b-col
              cols="7"
              class="col-deposit"
            >
              <div>Change</div>
              <b-form-input
                v-model="limitVoucher"
                type="number"
                :no-wheel="true"
              />
            </b-col>

            <b-button
              variant="primary"
              :disabled="loadingVoucherLimit"
              class="btn-set ml-2 mt-2"
              @click="handleApplyVoucherLimit"
            >
              <Spinner v-if="loadingVoucherLimit" />
              <template v-else>
                Apply
              </template>
            </b-button>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            DEPOSIT VOUCHER PRICE
          </div>
        </b-col>
        <b-col cols="2">
          <div>Current</div>
          <b-form-input
            id="voucher-limit"
            :value="formatCurrencyBuiltInFunc(currentDepositVoucherPrice)"
            readonly
          />
        </b-col>

        <b-col cols="5">
          <b-row>
            <b-col
              cols="7"
              class="col-deposit"
            >
              <div>Change</div>
              <b-form-input
                v-model="depositVoucherPrice"
                type="number"
                :no-wheel="true"
              />
            </b-col>

            <b-button
              variant="primary"
              :disabled="loadingDepositVoucherPrice"
              class="btn-set ml-2 mt-2"
              @click="handleChangeDepositVoucherPrice"
            >
              <Spinner v-if="loadingDepositVoucherPrice" />
              <template v-else>
                Apply
              </template>
            </b-button>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            REBUY POINT
          </div>
        </b-col>
        <b-col cols="2">
          <div>Current</div>
          <b-form-input
            id="voucher-limit"
            :value="currentReBuyPoint"
            readonly
          />
        </b-col>

        <b-col cols="5">
          <b-row>
            <b-col
              cols="7"
              class="col-deposit"
            >
              <div>Change</div>
              <b-form-input
                v-model="rebuyPoint"
                type="number"
                :no-wheel="true"
              />
            </b-col>

            <b-button
              variant="primary"
              :disabled="loadingReBuyPoint"
              class="btn-set ml-2 mt-2"
              @click="handleApplyReBuyPoint"
            >
              <Spinner v-if="loadingReBuyPoint" />
              <template v-else>
                Apply
              </template>
            </b-button>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            MAXIMUM WITHDRAWAL
          </div>
        </b-col>
        <b-col cols="2">
          <div>Current</div>
          <b-form-input
            id="voucher-limit"
            :value="formatCurrencyBuiltInFunc(currentMaximumWithdrawal)"
            readonly
          />
        </b-col>

        <b-col cols="5">
          <b-row>
            <b-col
              cols="7"
              class="col-deposit"
            >
              <div>Change</div>
              <b-form-input
                v-model="maximumWithdrawal"
                :formatter="formatNumberPrize"
              />
            </b-col>

            <b-button
              variant="primary"
              :disabled="loadingReBuyPoint"
              class="btn-set ml-2 mt-2"
              @click="handleApplyMaximumAmount"
            >
              <Spinner v-if="loadingMaximumWithdrawal" />
              <template v-else>
                Apply
              </template>
            </b-button>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            SHOW VOUCHER
          </div>
        </b-col>
        <b-col cols="2">
          <div>Current</div>
          <b-form-input
            :value="currentDisableVoucher ? 'Disabled' : 'Enabled'"
            readonly
          />
        </b-col>

        <b-col cols="5">
          <b-row>
            <b-button
              variant="primary"
              :disabled="loadingDisableVoucher"
              class="btn-set ml-14 mt-2"
              @click="handleToggleDisableVoucher"
            >
              <Spinner v-if="loadingDisableVoucher" />
              <template v-else>
                {{ currentDisableVoucher ? 'Enable' : 'Disable' }}
              </template>
            </b-button>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            SHOW MAXPP
          </div>
        </b-col>
        <b-col cols="2">
          <div>Current</div>
          <b-form-input
            :value="currentShowMaxPP ? 'Disabled' : 'Enabled'"
            readonly
          />
        </b-col>

        <b-col cols="5">
          <b-row>
            <b-button
              variant="primary"
              :disabled="loadingToggleShowMaxPP"
              class="btn-set ml-14 mt-2"
              @click="handleToggleShowMaxPP"
            >
              <Spinner v-if="loadingToggleShowMaxPP" />
              <template v-else>
                {{ currentShowMaxPP ? 'Enable' : 'Disable' }}
              </template>
            </b-button>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            PHONE NUMBER ALERT NOT RESULT EVENT
          </div>
        </b-col>
        <b-col cols="7">
          <b-row
            v-for="(item, ind) in listPhone"
            :key="ind"
          >
            <b-col cols="5">
              <div>Current</div>
              <b-form-input
                id="voucher-limit"
                :value="item"
                readonly
              />
            </b-col>

            <b-button
              variant="danger"
              :disabled="loadingDeletePhone && deleteItem === item"
              class="btn-set ml-2 mt-2"
              @click="handleDeleltePhoneNumber(item)"
            >
              <Spinner v-if="loadingDeletePhone && deleteItem === item" />
              <template v-else>
                Delete
              </template>
            </b-button>
          </b-row>
          <b-row>
            <b-col cols="5">
              <div>New Phone No</div>
              <b-form-input
                v-model="newPhoneNo"
                placeholder="Enter +61xxxxxxxx"
              />
            </b-col>

            <b-button
              variant="primary"
              :disabled="loadingNewNumber"
              class="btn-set ml-2 mt-2"
              @click="handleApplyNumber"
            >
              <Spinner v-if="loadingNewNumber" />
              <template v-else>
                Apply
              </template>
            </b-button>
          </b-row>

        </b-col>

      </b-row>

      <div class="d-flex align-items-center my-2">
        <feather-icon
          icon="SettingsIcon"
          size="18"
        />
        <h4 class="mb-0 ml-50">
          Punter Club Settings:
        </h4>
      </div>
      <b-row class="mt-2">
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            JOIN PUNTER CLUB LIMIT
          </div>
        </b-col>
        <b-col cols="2">
          <div>Current</div>
          <b-form-input
            id="voucher-limit"
            :value="currentLimitPunterClub"
            readonly
          />
        </b-col>

        <b-col cols="5">
          <b-row>
            <b-col
              cols="7"
              class="col-deposit"
            >
              <div>Change</div>
              <b-form-input
                v-model="limitPunterClub"
                type="number"
                :no-wheel="true"
              />
            </b-col>

            <b-button
              variant="primary"
              :disabled="loadingPunterLimit"
              class="btn-set ml-2 mt-2"
              @click="handleApplyChangePunterLimit"
            >
              <Spinner v-if="loadingPunterLimit" />
              <template v-else>
                Apply
              </template>
            </b-button>
          </b-row>
        </b-col>
      </b-row>

      <div class="d-flex align-items-center my-2">
        <feather-icon
          icon="SettingsIcon"
          size="18"
        />
        <h4 class="mb-0 ml-50">
          Tipping Settings:
        </h4>
      </div>
      <!-- SETTING tipping-keeping-percent -->
      <b-row class="mt-2">
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            TIPPING KEEPING PERCENT
          </div>
        </b-col>
        <b-col cols="2">
          <div>Current</div>
          <b-form-input
            id="keeping-percent"
            :value="currenttippingKeepPercent"
            readonly
          />
        </b-col>

        <b-col cols="5">
          <b-row>
            <b-col
              cols="7"
              class="col-deposit"
            >
              <div>Change</div>
              <b-form-input
                v-model="tippingKeepPercent"
                type="number"
                :no-wheel="true"
              />
            </b-col>

            <b-button
              variant="primary"
              :disabled="loadingTippingKeep"
              class="btn-set ml-2 mt-2"
              @click="handleApplyChangeTippingKeeping"
            >
              <Spinner v-if="loadingTippingKeep" />
              <template v-else>
                Apply
              </template>
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

  </section>
</template>

<script>
import {
  BCol, BRow, BButton, BCard, BFormInput,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { mapActions } from 'vuex'
import {
  formatDate, formatDateTimeAustralia, formatDateQuery, formatCurrencyBuiltInFunc,
} from '@/helpers/utils'
import Spinner from '@core/components/Spinner'
import useToastHandler from '@/services/toastHandler'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BFormInput,
    BButton,
    Spinner,
  },
  data() {
    return {
      eventId: null,
      eventItem: null,
      eventDetailId: null,
      eventDetailItem: null,
      meetingsDated: [],
      listRace: [],
      formatDateTimeAustralia,
      formatDate,
      time: '',
      amount: null,
      price: null,
      limitVoucher: null,
      depositVoucherPrice: null,
      rebuyPoint: null,
      maximumWithdrawal: null,
      limitPunterClub: null,

      currentLimitBetSlip: null,
      currentVoucherPrice: null,
      currentLimitVoucher: null,
      currentReBuyPoint: null,
      currentDisableVoucher: null,
      currentDepositVoucherPrice: null,
      currentMaximumWithdrawal: null,
      currentShowMaxPP: null,
      disableRebuyBonus: false,
      currentLimitPunterClub: null,

      loadingLimitBetSlip: false,
      loadingVoucherPrice: false,
      loadingVoucherLimit: false,
      loadingReBuyPoint: false,
      loadingDisableVoucher: false,
      loadingToggleShowMaxPP: false,
      loadingDepositVoucherPrice: false,
      loadingMaximumWithdrawal: false,

      loadingApplyBonusToken: false,
      loadingDisableBonusRebuyToken: false,
      loadingSaveVourcherSetting: false,

      loadingPunterLimit: false,
      currenttippingKeepPercent: null,
      tippingKeepPercent: null,
      loadingTippingKeep: false,

      listPhone: [],
      newPhoneNo: null,
      loadingNewNumber: false,
      loadingDeletePhone: false,
      deleteItem: null,
    }
  },
  watch: {
  },
  async mounted() {
    const [
      joinLimit,
      stake,
      maxAmountVoucher,
      reBuyTokenPoint,
      disableVoucher,
      reverseStake,
      maxAmount,
      disableShowMaxPP,
      punterClub,
      tippingKeep,
      listPhone,
    ] = await Promise.all([
      this.getSetting('join-limit'),
      this.getSetting('stake'),
      this.getSetting('max-amount-voucher'),
      this.getSetting('rebuy-token-point'),
      this.getSetting('disable-voucher'),
      this.getSetting('reverse-stake'),
      this.getSetting('max-amount'),
      this.getSetting('disable-maxpp'),
      this.getSetting('max-join-punter-group-times'),
      this.getSetting('tipping-keeping-percent'),
      this.getSetting('alert-not-resulted-event'),
    ])
    this.currentLimitBetSlip = joinLimit
    this.currentVoucherPrice = stake
    this.currentLimitVoucher = maxAmountVoucher
    this.currentReBuyPoint = reBuyTokenPoint
    this.currentDisableVoucher = disableVoucher
    this.currentDepositVoucherPrice = reverseStake
    this.currentMaximumWithdrawal = maxAmount
    this.currentShowMaxPP = disableShowMaxPP
    this.currentLimitPunterClub = punterClub
    this.currenttippingKeepPercent = tippingKeep
    this.listPhone = listPhone
  },
  methods: {
    ...mapActions({
      getSetting: 'setting/setting/getSetting',
      setNewSetting: 'setting/setting/setNewSetting',
      setLimitNumber: 'setting/limitNumber/setLimitNumber',
    }),

    formatNumberPrize(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },

    async handleApplyLimitBetslip() {
      if (!this.amount) return
      this.loadingLimitBetSlip = true
      const res = await this.setNewSetting({ key: 'join-limit', number: this.amount })
      if (res) {
        this.currentLimitBetSlip = this.amount
      }
      this.loadingLimitBetSlip = false
      this.amount = null
    },
    async handleApplyVoucherPrice() {
      if (!this.price) return
      this.loadingVoucherPrice = true
      const res = await this.setNewSetting({ key: 'stake', number: this.price })
      if (res) {
        this.currentVoucherPrice = this.price
      }
      this.loadingVoucherPrice = false
      this.price = null
    },
    async handleApplyVoucherLimit() {
      if (!this.limitVoucher) return
      this.loadingVoucherLimit = true
      const res = await this.setNewSetting({ key: 'max-amount-voucher', number: this.limitVoucher })
      if (res) {
        this.currentLimitVoucher = this.limitVoucher
      }
      this.loadingVoucherLimit = false

      this.limitVoucher = null
    },
    async handleApplyMaximumAmount() {
      if (!this.maximumWithdrawal) return
      this.loadingMaximumWithdrawal = true
      const res = await this.setNewSetting({ key: 'max-amount', number: this.maximumWithdrawal })
      if (res) {
        this.currentMaximumWithdrawal = this.maximumWithdrawal
      }
      this.loadingMaximumWithdrawal = false

      this.maximumWithdrawal = null
    },
    async handleApplyReBuyPoint() {
      if (!this.rebuyPoint) return
      this.loadingReBuyPoint = true
      const res = await this.setNewSetting({ key: 'rebuy-token-point', number: this.rebuyPoint })
      if (res) {
        this.currentReBuyPoint = this.rebuyPoint
      }
      this.loadingReBuyPoint = false

      this.rebuyPoint = null
    },
    async handleToggleDisableVoucher() {
      this.loadingDisableVoucher = true
      const res = await this.setNewSetting({ key: 'disable-voucher', number: !this.currentDisableVoucher })
      if (res) {
        this.currentDisableVoucher = !this.currentDisableVoucher
      }
      this.loadingDisableVoucher = false
    },
    async handleToggleShowMaxPP() {
      this.loadingToggleShowMaxPP = true
      const res = await this.setNewSetting({ key: 'disable-maxpp', number: !this.currentShowMaxPP })
      if (res) {
        this.currentShowMaxPP = !this.currentShowMaxPP
      }
      this.loadingToggleShowMaxPP = false
    },

    async handleToggleDisableBonusRebuyToken() {
      this.loadingDisableBonusRebuyToken = true
      const res = await this.setNewSetting({ key: 'rebuy-token-join-receive', number: this.disableRebuyBonus ? 1 : 0 })
      if (res) {
        this.disableRebuyBonus = !this.disableRebuyBonus
      }
      this.loadingDisableBonusRebuyToken = false
    },
    async handleChangeDepositVoucherPrice() {
      if (!this.depositVoucherPrice) return

      this.loadingDepositVoucherPrice = true
      const res = await this.setNewSetting({ key: 'reverse-stake', number: this.depositVoucherPrice })
      if (res) {
        this.currentDepositVoucherPrice = this.depositVoucherPrice
      }
      this.loadingDepositVoucherPrice = false
      this.depositVoucherPrice = null
    },
    async handleApplyChangePunterLimit() {
      if (!this.limitPunterClub) return
      this.loadingPunterLimit = true
      const res = await this.setLimitNumber({ key: 'max-join-punter-group-times', number: this.limitPunterClub })
      if (res) {
        this.currentLimitPunterClub = this.limitPunterClub
      }
      this.loadingPunterLimit = false
      this.limitPunterClub = null
    },
    async handleApplyChangeTippingKeeping() {
      if (!this.tippingKeepPercent) return
      this.loadingTippingKeep = true
      const res = await this.setLimitNumber({ key: 'tipping-keeping-percent', number: this.tippingKeepPercent })
      if (res) {
        this.currenttippingKeepPercent = this.tippingKeepPercent
      }
      this.loadingTippingKeep = false
      this.tippingKeepPercent = null
    },
    formatNumber(value) {
      let val = value.replace(/[^0-9.]/g, '')
      if (!Number.isInteger(Number(val))) {
        val = ''
      }
      const parts = val.split('.')
      parts[0] = parts[0].replace(/^0+/, '')
      val = parts.join('.')
      const decimalRegex = /^\d*\.?\d*$/
      if (!decimalRegex.test(val)) {
        val = ''
      }
      const numericVal = parseFloat(val)
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(numericVal)) {
        val = ''
      } else if (numericVal < 1) {
        val = '1'
      }
      return val
    },
    formatPrice(value) {
      if (value < 0) return null
      return value
    },
    async handleApplyNumber() {
      if (!this.newPhoneNo) return
      this.loadingNewNumber = true
      const res = await this.setNewSetting({ key: 'alert-not-resulted-event', number: [...this.listPhone, this.newPhoneNo] })
      if (res) {
        this.listPhone = await this.getSetting('alert-not-resulted-event')
      }
      this.loadingNewNumber = false
      this.newPhoneNo = null
    },
    async handleDeleltePhoneNumber(item) {
      this.deleteItem = item
      this.loadingDeletePhone = true
      const res = await this.setNewSetting({ key: 'alert-not-resulted-event', number: this.listPhone.filter(i => i !== item) })
      if (res) {
        this.listPhone = await this.getSetting('alert-not-resulted-event')
      }
      this.loadingDeletePhone = false
      this.newPhoneNo = null
      this.deleteItem = null
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()
    return {
      showToastSuccess,
      showToastError,
      formatDateQuery,
      formatCurrencyBuiltInFunc,
    }
  },
}
</script>

<style lang="scss" scoped>
.btn-set {
  height: fit-content;
}
.add-btn {
  padding: 2px 4px;
}

.remove-btn {
  padding: 0px 4px 2px 4px;
  margin-left: 1px;
}

.delete-btn {
  color: red;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.v-select .vs__dropdown-toggle,
.v-select .vs__search {
  cursor: pointer;
}
@import '@core/scss/vue/libs/vue-select.scss';

.ml-14 {
  margin-left: 14px;
}
</style>
