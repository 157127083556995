<template>
  <div class="mt-2">
    <!-- Table Container Card -->
    <b-card>
      <b-row class="ml-1 mb-2">
        <b-form-radio-group
          id="get-prize"
          v-model="selectedSport"
          :options="optionsSport"
          name="radio-inline"
        />
      </b-row>
      <b-button
        variant="primary"
        class="mb-2 mr-2"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        style="border-radius: 18px;"
        @click="handleShowListEvent"
      >
        <span> ADD EVENT</span>
      </b-button>
      <b-button
        variant="outline-danger"
        class="mb-2"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        style="border-radius: 18px;"
        :disabled="listAllEventId.length === 0"
        @click="handleShowModalDeleteAll"
      >
        <span> REMOVE ALL</span>
      </b-button>

      <b-table
        ref="refEventTable"
        :items="fetchEvent"
        :fields="tableColumns"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="fetchEvent.length === 0"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>
        <!-- event -->
        <template #cell(event)="{ item }">
          <b-media vertical-align="center">
            <div class="font-weight-bold d-block text-nowrap text-body cursor-pointer">
              {{ item.meetingName }}
            </div>
          </b-media>
        </template>
        <!-- raceNumber -->
        <template #cell(raceNumber)="{ item }">
          <b-media vertical-align="center">
            <div class="font-weight-bold d-block text-nowrap text-body cursor-pointer">
              {{ item.number }}
            </div>
          </b-media>
        </template>
        <!-- raceName -->
        <template #cell(raceName)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.name }}
            </div>
          </b-media>
        </template>
        <!-- raceName -->
        <template #cell(date)="{ item }">
          <b-media vertical-align="center">
            <div
              class="d-flex align-items-center font-weight-bold"
              style="width: 90px"
            >
              {{ formatTime(item.startTime, 'dd-MM-yyyy') }}
            </div>
          </b-media>
        </template>
        <!-- time -->
        <template #cell(time)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ formatTime(item.startTime) }}
            </div>
          </b-media>
        </template>
        <!-- Column: delete -->
        <template #cell(delete)="{ item }">
          <div class="d-flex align-items-center font-weight-bold id-column">
            <div
              class="d-flex align-items-center font-weight-extrabold"
              style="cursor: pointer;"
              @click="handleShowModalDelete(item)"
            >
              <feather-icon icon="TrashIcon" />

            </div>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalSelectedEvent"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- MODAL ADD EVENT -->
    <b-modal
      ref="event"
      hide-footer
      title="List Event"
      centered
      cancel-variant="outline-secondary"
      size="xl"
    >
      <b-table
        ref="refListTable"
        :items="fetchList"
        :fields="tableColumnsList"
        responsive
        show-empty
        primary-key="id"
        select-mode="multi"
        selectable
        @row-selected="onRowSelected"
      >
        <template #empty>
          <div
            v-if="fetchList.length === 0"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>
        <!-- event -->
        <template #cell(event)="{ item }">
          <b-media vertical-align="center">
            <div class="font-weight-bold d-block text-nowrap text-body cursor-pointer">
              {{ item.meetingName }}
            </div>
          </b-media>
        </template>
        <!-- raceNumber -->
        <template #cell(raceNumber)="{ item }">
          <b-media vertical-align="center">
            <div class="font-weight-bold d-block text-nowrap text-body cursor-pointer">
              {{ item.number }}
            </div>
          </b-media>
        </template>
        <!-- raceName -->
        <template #cell(raceName)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.name }}
            </div>
          </b-media>
        </template>
        <!-- raceName -->
        <template #cell(date)="{ item }">
          <b-media vertical-align="center">
            <div
              class="d-flex align-items-center font-weight-bold"
              style="width: 90px"
            >
              {{ formatTime(item.startTime, 'dd-MM-yyyy') }}
            </div>
          </b-media>
        </template>
        <!-- time -->
        <template #cell(time)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ formatTime(item.startTime) }}
            </div>
          </b-media>
        </template>
        <!-- Column: delete -->
        <template #cell(delete)="{ item }">
          <div class="d-flex align-items-center font-weight-bold id-column">
            <div
              class="d-flex align-items-center font-weight-extrabold"
              style="cursor: pointer;"
              @click="handleShowModalDelete(item)"
            >
              <feather-icon icon="TrashIcon" />

            </div>
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col class="d-flex justify-content-end ">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="listSelectedEvent.length === 0"
            @click="handleAddList"
          >
            <Spinner v-if="loadingAdd" />
            <span v-else>Add</span>
          </b-button>
        </b-col>
      </b-row>

    </b-modal>
    <!-- MODAL DELETE GROUP -->
    <b-modal
      ref="delete-event"
      title="Delete"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="loadingAdd"
      @ok="handleDeleteEvent('all')"
    >
      <b-card-text style="color: black; font-weight: bold;">
        Do you want remove {{ eventDeleteId ? `the event ${eventDeleteId}` : 'all events' }}  in list?
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="loadingAdd" />
        <span v-else>Delete</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable, BPagination, BRow, BCol, BCard,
  BButton, VBModal,
  BCardText, BMedia, BFormRadioGroup,
} from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import {
  formatDate, formatDateTimeLocal, formatTime, formatDateSend,
} from '@/helpers/utils'
import useToastHandler from '@/services/toastHandler'
import { arrayProp } from '@/helpers/props'
import { computed, ref, watch } from '@vue/composition-api'
import Spinner from '@core/components/Spinner'
import { addDays, subDays } from 'date-fns'

export default {
  components: {
    BTable,
    BPagination,
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    Spinner,
    BMedia,
    BFormRadioGroup,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    entriesList: arrayProp(),
  },
  data() {
    return {
      groups: [],
      searchText: '',
      selectedGroups: [],
      page: 1,
      totalListAll: null,
      totalPage: 1,
      timeout: null,
      eventDeleteId: null,

      listSelectedEvent: [],
      listAllEventId: [],
      optionsSport: [
        { text: 'AFL', value: 'AFL' },
        { text: 'NRL', value: 'NRL' },
      ],
      selectedSport: 'AFL',
    }
  },
  computed: {
    ...mapState('sportComp/listEvent', ['loadingAdd']),

  },
  watch: {
    selectedSport() {
      this.refetchData()
    },
  },
  async created() {
    const res = await this.getListLeaguesSport()
    if (Array.isArray(res.data)) {
      const formatList = res.data.map(i => ({
        text: i.sportLeagueId,
        value: i.sportLeagueId,
        sportCode: i.sportCode,
      }))
      this.optionsSport = formatList
    }
  },
  methods: {
    ...mapActions({
      getListSelectedEvent: 'sportComp/listEvent/getListSelectedEvent',
      getListAllEvent: 'sportComp/listEvent/getListAllEvent',
      setAddEvent: 'sportComp/listEvent/setAddEvent',
      getListLeaguesSport: 'sportComp/listEvent/getListLeaguesSport',
    }),
    async fetchEvent() {
      const data = await this.getListSelectedEvent({ league: this.selectedSport })

      if (!data && data.data.length <= 0) return this.showToastError(false, 'Error fetching participant list')
      this.totalSelectedEvent = data.pagination.total
      this.listAllEventId = data.data
      return data.data
    },
    async fetchList() {
      const data = await this.getListAllEvent({
        time: this.formatDateSend(subDays(new Date(), 7)),
        endTime: this.formatDateSend(addDays(new Date(), 30)),
        league: this.selectedSport,
      })
      return data.data
    },
    formatter(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }

        if (Number(value) > this.totalPage) return this.totalPage
        if (Number(value) < 1) return '1'
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    handleShowListEvent() {
      this.$refs.event.show()
    },
    handleShowModalDelete(item) {
      this.eventDeleteId = item.eventId
      this.$refs['delete-event'].show()
    },
    handleShowModalDeleteAll() {
      this.$refs['delete-event'].show()
    },
    async handleDeleteEvent(val) {
      if (val === 'all' && !this.eventDeleteId) {
        const listId = this.listAllEventId.map(i => i.eventId)
        const res = await this.setAddEvent({
          isPublish: 0,
          eventIds: listId,
        })
        if (res) {
          this.showToastSuccess('Success', 'Remove all events successfully!')
          this.refetchData()
          this.eventDeleteId = null
        }
        return
      }
      const res = await this.setAddEvent({
        isPublish: 0,
        eventIds: [this.eventDeleteId],
      })
      if (res) {
        this.showToastSuccess('Success', 'Remove this event successfully!')
        this.refetchData()
        this.eventDeleteId = null
      }
    },
    onRowSelected(items) {
      this.listSelectedEvent = items
    },
    async  handleAddList() {
      if (Array.isArray(this.listSelectedEvent)) {
        const eventIds = this.listSelectedEvent.map(i => i.eventId)
        const data = {
          isPublish: 1,
          eventIds,
        }
        const res = await this.setAddEvent(data)
        if (res) {
          this.refetchData()
          this.showToastSuccess('Success', 'Add list event successfully!')
          this.$refs.event.hide()
        }
      }
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()

    const refEventTable = ref(null)

    // Table Handlers
    const tableColumns = [
      {
        key: 'eventId',
      },
      {
        key: 'eventTitle',
        label: 'Event name',
      },
      {
        key: 'date',
      },
      {
        key: 'time',
        label: 'START TIME',
      },
      {
        key: 'league',
      },
      { key: 'delete' },
    ]

    const tableColumnsList = [
      {
        key: 'eventId',
      },
      {
        key: 'eventTitle',
        label: 'Event name',
      },
      {
        key: 'date',
      },
      {
        key: 'time',
        label: 'START TIME',
      },
      {
        key: 'league',
      },
    ]

    const perPage = ref(100)
    const totalSelectedEvent = ref(0)
    const totalRaces = ref(0)
    const currentPage = ref(1)

    const refetchData = () => {
      refEventTable.value.refresh()
    }

    const dataMeta = computed(() => {
      const localItemsCount = refEventTable.value ? refEventTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalSelectedEvent.value,
      }
    })

    watch([currentPage, perPage], () => {
      refetchData()
    })

    return {
      perPage,
      totalSelectedEvent,
      dataMeta,

      formatDate,
      formatDateTimeLocal,
      showToastError,
      tableColumns,
      currentPage,
      totalRaces,
      refEventTable,
      refetchData,

      formatTime,

      showToastSuccess,
      formatDateSend,

      tableColumnsList,
    }
  },
}
</script>

    <style lang="scss" scoped>
    .id-column {
      max-width: 150px;
      word-break: break-word;
    }
    </style>
