<template>
  <div style="width: 100%">
    <b-table
      ref="refBonusCashListTable"
      :items="fetchBonusOffers"
      :fields="tableColumns"
      responsive
      show-empty
      primary-key="campaign"
    >
      <template #empty>
        <div
          v-if="loadingListBonus"
          class="text-center text-dark my-2"
        >
          <p>Loading... Please wait</p>
        </div>

        <div
          v-else-if="!loadingListBonus && fetchBonusOffers.length === 0"
          class="text-center text-dark my-2"
        >
          No matching records found
        </div>
      </template>

      <!-- Column: id -->
      <template #cell(id)="{ item }">
        <div class="d-flex align-items-center font-weight-bold">
          <div class="d-flex align-items-center font-weight-extrabold">
            {{ item.bonusOfferId }}
          </div>
        </div>
      </template>
      <!-- Column: type -->
      <template #cell(type)="{ item }">
        <div class="d-flex align-items-center font-weight-bold">
          <b-badge
            pill
            :variant="formatColorType(item.type)"
          >
            {{ item.type }}
          </b-badge>
        </div>
      </template>
      <!-- Column: bonus percent -->
      <template #cell(bonusPercent)="{ item }">
        <div class="d-flex align-items-center font-weight-bold id-column">
          <div class="d-flex align-items-center font-weight-extrabold">
            {{ item.data.percent }} {{ item.data.percent ? '%' : '' }}
          </div>
        </div>
      </template>

      <!-- Column: bonus limit -->
      <template #cell(bonusLimit)="{ item }">
        <div class="d-flex align-items-center font-weight-bold id-column">
          <div class="d-flex align-items-center font-weight-extrabold">
            {{ formatCurrencyBuiltInFunc(item.data.bonusLimit) }}
          </div>
        </div>
      </template>
      <!-- Column: competitionId -->
      <template #cell(competitionId)="{ item }">
        <div class="d-flex align-items-center font-weight-bold id-column">
          <div class="d-flex align-items-center font-weight-extrabold">
            {{ item.data.competitionId }}
          </div>
        </div>
      </template>
      <!-- Column: amountBetSlip -->
      <template #cell(amountBetSlip)="{ item }">
        <div class="d-flex align-items-center font-weight-bold id-column">
          <div class="d-flex align-items-center font-weight-extrabold">
            {{ item.data.amountBetSlip }}
          </div>
        </div>
      </template>
      <!-- Column: bonus -->
      <template #cell(bonus)="{ item }">
        <div class="d-flex align-items-center font-weight-bold id-column">
          <div class="d-flex align-items-center font-weight-extrabold">
            {{ formatCurrencyBuiltInFunc(item.data.bonus) }}
          </div>
        </div>
      </template>

      <!-- Column: open at -->
      <template #cell(openDate)="{ item }">
        <div class="d-flex align-items-center font-weight-bold id-column">
          <div class="d-flex align-items-center font-weight-extrabold">
            {{ formatTime(item.openAt, 'HH:mm:ss dd-MM-yyyy') }}
          </div>
        </div>
      </template>

      <!-- Column: expired at -->
      <template #cell(depositExpiredDays)="{ item }">
        <div class="d-flex align-items-center font-weight-bold id-column">
          <div class="d-flex align-items-center font-weight-extrabold">
            {{ item.bonusExpiredDays }}
          </div>
        </div>
      </template>

      <!-- Column: close at -->
      <template #cell(closeDate)="{ item }">
        <div class="d-flex align-items-center font-weight-bold id-column">
          <div class="d-flex align-items-center font-weight-extrabold">
            {{ formatTime(item.closeAt, 'HH:mm:ss dd-MM-yyyy') }}
          </div>
        </div>
      </template>

      <!-- Column: Customer profile -->
      <template #cell(userProfile)="{ item }">
        <div class="d-flex align-items-center font-weight-bold id-column">
          <div
            v-if="checkShowColumnProfile(item.bonusOfferAppliables).length > 0"
            class="d-flex align-items-left font-weight-extrabold"
            style="flex-direction: column; gap: 2px"
          >
            <b-badge
              v-for="(i, index) in formatUserProfileMix(checkShowColumnProfile(item.bonusOfferAppliables))"
              :key="index"
              pill
              variant="light-success"
            >
              {{ i }}
            </b-badge>
          </div>
        </div>
      </template>

      <!-- Column: username -->
      <template #cell(username)="{ item }">
        <div class="d-flex align-items-center font-weight-bold id-column">
          <div
            v-if="checkShowColumnProfile(item.bonusOfferAppliables).length > 0"
            class="d-flex align-items-left font-weight-extrabold"
            style="flex-direction: column; gap: 2px"
          >
            <b-badge
              v-for="(i, index) in formatUserProfileMix(checkShowColumnUser(item.bonusOfferAppliables))"
              :key="index"
              pill
              variant="light-success"
            >
              {{ i }}
            </b-badge>
          </div>
          <div
            v-if="
              ((item.bonusOfferAppliables[0] && item.bonusOfferAppliables[0].appliableType === 'UserId') ||
                item.type === 'direct') &&
                checkShowColumnProfile(item.bonusOfferAppliables).length === 0
            "
            class="d-flex align-items-left font-weight-extrabold"
            style="flex-direction: column; gap: 2px"
          >
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="mx-1"
              style="cursor: pointer"
              @click="handleShowListUserOffer(item)"
            />
          </div>
        </div>
      </template>

      <!-- Column: action -->
      <template #cell(action)="{ item }">
        <div class="d-flex align-items-center font-weight-bold id-column">
          <b-dropdown
            variant="link"
            no-caret
            boundary="viewport"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="item.bonusOfferAppliables.length > 0"
              @click="handleShowAddCustomerProfile(item)"
            >
              <feather-icon icon="PlusIcon" />
              <span class="align-middle ml-50">
                {{
                  checkShowColumnProfile(item.bonusOfferAppliables).length > 0 ? 'Add Customer Profile' : 'Add UserName'
                }}
              </span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="item.bonusOfferAppliables.length > 0"
              @click="handleShowRemoveCustomerProfile(item)"
            >
              <feather-icon icon="DeleteIcon" />
              <span class="align-middle ml-50">
                {{
                  checkShowColumnProfile(item.bonusOfferAppliables).length > 0
                    ? 'Remove Customer Profile'
                    : 'Remove UserName'
                }}
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="item.type === 'direct'"
              @click="handleAddDirect(item)"
            >
              <feather-icon icon="PlusIcon" />
              <span class="align-middle ml-50"> Add direct </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="item.bonusOfferAppliables.length === 0 && item.type !== 'direct'"
              @click="handleShowAddProfile(item)"
            >
              <feather-icon icon="PlusIcon" />
              <span class="align-middle ml-50"> Add Customer Profile </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="item.bonusOfferAppliables.length === 0 && item.type !== 'direct'"
              @click="handleShowAddUser(item)"
            >
              <feather-icon icon="PlusIcon" />
              <span class="align-middle ml-50"> Add List UserName </span>
            </b-dropdown-item>
            <b-dropdown-item @click="handleShowEditModal(item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit Offer</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showModal(item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"> Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} offers </span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalOffers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { isRoleSuperAdmin } from '@/helpers/permission'
import useToastHandler from '@/services/toastHandler'
import { avatarText } from '@core/utils/filter'
import {
  BTable, VBModal, BBadge, BDropdown, BDropdownItem, BPagination, BCol, BRow,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
// import ExportCSV from './ExportCSV.vue'
import {
  formatTime,
  formatDate,
  formatCurrencyBuiltInFunc,
  formatDateTimeLocal,
  formatDateTimeTracking,
} from '@/helpers/utils'
import { formatDistanceStrict } from 'date-fns'
import { computed, ref, watch } from '@vue/composition-api'
import { objectProp } from '@/helpers/props'

export default {
  components: {
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCol,
    BRow,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    fieldFilter: objectProp(),
    orderFilter: objectProp(),
    statusFilter: objectProp(),
  },
  data() {
    return {
      deleteId: null,
      tableColumns: [
        { key: 'id' },
        { key: 'type' },
        { key: 'name' },
        { key: 'description' },
        { key: 'bonusPercent', label: 'Bonus percent' },
        { key: 'bonusLimit', label: 'Bonus limit' },
        { key: 'competitionId' },
        { key: 'amountBetSlip', label: 'Full Betslip Amount' },
        { key: 'bonus', label: 'Bonus Amount In Comp' },
        { key: 'openDate', label: 'open at' },
        { key: 'depositExpiredDays', label: 'Bonus Expired day' },
        { key: 'closeDate', label: 'close at' },
        { key: 'userProfile', label: 'Customer profile' },
        { key: 'username' },
        { key: 'action' },
      ],
    }
  },
  computed: {
    ...mapState('races/bonusCash', ['loadingListBonus', 'loadingCreateBonus']),
  },
  watch: {
    isCreateSuccess(val) {
      if (val === true) {
        this.checkCreateCampaign(false)
        this.refBonusCashListTable.refresh()
      }
    },
    fieldFilter() {
      this.refBonusCashListTable.refresh()
    },
    orderFilter() {
      this.refBonusCashListTable.refresh()
    },
    statusFilter() {
      this.refBonusCashListTable.refresh()
    },
    loadingCreateBonus() {
      this.refBonusCashListTable.refresh()
    },
  },

  methods: {
    ...mapActions({
      getListBonus: 'races/bonusCash/getListBonus',
      getOffer: 'races/bonusCash/getOffer',
      setBonusOfferId: 'races/bonusCash/setBonusOfferId',
      getUserBonusOfferDirect: 'races/bonusCash/getUserBonusOfferDirect',
    }),
    formatColorType(val) {
      if (val === 'deposit') return 'light-success'
      if (val === 'direct') return 'light-danger'
      return 'light-warning'
    },
    async fetchBonusOffers() {
      const data = await this.getListBonus({
        'sort[field]': this.fieldFilter.value,
        'sort[order]': this.orderFilter.value,
        ...(this.statusFilter && { isActive: this.statusFilter.value }),
        page: this.currentPage,
        limit: this.perPage,
      })

      this.totalOffers = data.pagination.total

      if (!data) return this.showToastError(false, 'Error fetching offer list')
      return data.data
    },
    handleShowEditModal(item) {
      const data = {
        item,
        type: 'edit',
      }
      this.$emit('update-data', data)
    },
    handleShowAddCustomerProfile(val) {
      const item = {
        ...val,
        ...(this.checkShowColumnProfile(val.bonusOfferAppliables).length > 0 && {
          bonusOfferAppliables: this.checkShowColumnProfile(val.bonusOfferAppliables),
        }),
      }
      const data = {
        item,
        type: 'add-profile',
        ...(item.bonusOfferAppliables[0].appliableType === 'UserId' && { note: 'list-user' }),
      }
      this.$emit('update-data', data)
    },
    handleShowAddProfile(item) {
      const data = {
        item,
        type: 'add-profile',
        note: 'new-profile',
      }
      this.$emit('update-data', data)
    },
    handleShowAddUser(item) {
      const data = {
        item,
        type: 'add-profile',
        note: 'new-user',
      }
      this.$emit('update-data', data)
    },
    handleShowRemoveCustomerProfile(val) {
      const item = {
        ...val,
        ...(this.checkShowColumnProfile(val.bonusOfferAppliables).length > 0 && {
          bonusOfferAppliables: this.checkShowColumnProfile(val.bonusOfferAppliables),
        }),
      }
      const data = {
        item,
        type: 'remove-profile',
        ...(item.bonusOfferAppliables[0].appliableType === 'UserId' && { note: 'list-user' }),
      }
      this.$emit('update-data', data)
    },
    showModal(item) {
      this.$emit('show-modal', item)
    },
    formatUserProfile(item) {
      const list = item.bonusOfferAppliables.map(i => {
        if (i.appliableId === 'NOT_RATED') return 'NOT RATED'
        return i.appliableId
      })
      return list
    },
    formatUserProfileMix(arr) {
      const list = arr.map(i => {
        if (i.appliableId === 'NOT_RATED') return 'NOT RATED'
        return i.appliableId
      })
      return list
    },
    async handleShowListUserOffer(item) {
      this.setBonusOfferId(item.bonusOfferId)
      if (item.type === 'direct') {
        await this.getUserBonusOfferDirect({
          bonusOfferId: item.bonusOfferId,
          data: {
            page: 1,
            limit: 20,
          },
        })
      } else {
        await this.getOffer({
          bonusOfferId: item.bonusOfferId,
          data: {
            page: 1,
            limit: 20,
          },
        })
      }
      this.$emit('type', item.type)
      this.$emit('show-list-user', this.formatUserProfile(item))
    },
    handleAddDirect(item) {
      const data = {
        item,
        type: 'add-direct',
      }
      this.$emit('add-direct', data)
    },
    checkShowColumnProfile(list) {
      const arr = list.filter(i => i.appliableType === 'UserProfile')
      return arr
    },
    checkShowColumnUser(list) {
      const arr = list.filter(i => i.appliableType === 'UserId')
      return arr
    },
  },
  setup() {
    const refBonusCashListTable = ref(null)
    const totalOffers = ref(0)
    const currentPage = ref(1)
    const perPage = ref(100)

    const dataMeta = computed(() => {
      const localItemsCount = refBonusCashListTable.value ? refBonusCashListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalOffers.value,
      }
    })

    const refetchData = () => {
      refBonusCashListTable.value.refresh()
    }

    watch([currentPage], () => {
      refetchData()
    })

    const { showToastError, showToastSuccess } = useToastHandler()

    return {
      getUserData,

      // Filter
      avatarText,

      showToastError,
      showToastSuccess,

      isRoleSuperAdmin,
      formatDate,
      formatCurrencyBuiltInFunc,
      formatDateTimeLocal,
      formatDateTimeTracking,
      formatDistanceStrict,
      refBonusCashListTable,
      formatTime,

      totalOffers,
      currentPage,
      perPage,
      dataMeta,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.id-column {
  max-width: 300px;
  min-width: 120px;
  word-break: break-word;
}
.text-title {
  color: black;
  font-weight: 500;
}
</style>
