import { render, staticRenderFns } from "./CompViewTabSetting.vue?vue&type=template&id=4b0844c1&scoped=true&"
import script from "./CompViewTabSetting.vue?vue&type=script&lang=js&"
export * from "./CompViewTabSetting.vue?vue&type=script&lang=js&"
import style0 from "./CompViewTabSetting.vue?vue&type=style&index=0&id=4b0844c1&lang=scss&scoped=true&"
import style1 from "./CompViewTabSetting.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b0844c1",
  null
  
)

export default component.exports