<template>
  <b-row class="mb-1 d-flex align-items-center">
    <b-col cols="7">
      <div
        class="w-full d-flex align-items-center"
        style="min-height: 38px; padding: 6px 14px; border: 1px solid; border-radius: 6px; white-space: wrap"
      >
        {{ value }}
      </div>
    </b-col>
    <b-col
      cols="2"
      class="d-flex"
    >
      <b-button
        v-if="!value"
        variant="primary"
        class="mb-1 mb-sm-0 add-btn"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="handleShowModal"
      >
        <template> + </template>
      </b-button>
      <b-button
        v-else
        variant="primary"
        class="mb-1 mb-sm-0 remove-btn"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="handleRemoveType"
      >
        <template> - </template>
      </b-button>
    </b-col>
    <b-modal
      ref="customer-profile"
      hide-footer
      title="Customer Profiles"
      centered
      cancel-variant="outline-secondary"
      size="md"
    >
      <b-row class="text-modal">
        <b-form-group>
          <b-form-checkbox
            v-for="item in clientProfileList"
            :key="item"
            v-model="selectedTypeProfile"
            name="event-filter"
            :value="item"
            class="mb-1 ml-2"
            checked="false"
          >
            {{ item }}
          </b-form-checkbox>
        </b-form-group>
      </b-row>
    </b-modal>
  </b-row>
</template>

<script>
import useToastHandler from '@/services/toastHandler'
import { mapActions } from 'vuex'
import {
  BCol, BRow, BButton, VBModal, BFormCheckbox,
  BFormGroup,
} from 'bootstrap-vue'
import {
  arrayProp, numberProp, stringProp,
} from '@/helpers/props'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormGroup,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    value: stringProp(),
    typeNumber: numberProp(),
    selectedList: arrayProp(),
    clientProfileList: arrayProp(),
  },

  data() {
    return {
      loading: false,
      selectedTypeProfile: [],
      selectedCheckbox: [],
    }
  },
  computed: {
  },

  watch: {
    selectedTypeProfile(val) {
      if (val) {
        const data = {
          number: this.typeNumber,
          value: val[0],
          type: 'add',
        }
        this.$emit('update-data', data)

        this.$refs['customer-profile'].hide()
      }
    },
  },

  methods: {
    ...mapActions({
      setPickEvent: 'races/listRace/setPickEvent',
      setPickListEvent: 'races/listRace/setPickListEvent',
      setPickFrontBack: 'races/listRace/setPickFrontBack',
      setPickFrontBackNumber: 'races/listRace/setPickFrontBackNumber',
    }),
    async handleShowModal() {
      this.selectedTypeProfile = []
      this.$refs['customer-profile'].show()
    },
    handleHideModal() {
      this.$refs['customer-profile'].hide()
    },
    handleRemoveType() {
      const data = {
        number: this.typeNumber,
        type: 'remove',
        value: this.value,
      }
      this.$emit('update-data', data)
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    return {
      showToastSuccess,
      showToastError,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .add-btn {
    padding: 2px 4px;
  }

  .remove-btn {
    padding: 0px 4px 2px 4px;
    margin-left: 1px;
  }

  .delete-btn {
    color: red;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }

  .text-modal {
    color: black;
  }

  .modal.modal-footer {
    display: none !important;
  }
  </style>
