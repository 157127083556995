<template>
  <div>
    <LoadingPage v-if="loading" />

    <b-card v-else>
      <b-tabs
        v-model="tabIndex"
        pills
        lazy
      >
        <!-- Tab: Settings -->
        <b-tab>
          <template #title>
            <BLink
              :to="{}"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 0 ? 'active-title' : ''"
              >Setting</span>
            </BLink>
          </template>

          <CompViewTabSetting
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: voucher/rebuy token offer -->
        <b-tab>
          <template #title>
            <BLink
              :to="{ query: { tab: 'voucher-offer' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 1 ? 'active-title' : ''"
              >Voucher Offers</span>
            </BLink>
          </template>

          <CompViewTabVoucherOffer class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: bonus cash offer -->
        <b-tab>
          <template #title>
            <BLink
              :to="{ query: { tab: 'bonus-cash-offer' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 2 ? 'active-title' : ''"
              >Bonus Cash</span>
            </BLink>
          </template>

          <CompViewTabBonusCash class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: Event Sport -->
        <b-tab>
          <template #title>
            <BLink
              :to="{ query: { tab: 'event-sport' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 3 ? 'active-title' : ''"
              >Event Sport</span>
            </BLink>
          </template>

          <ListChooseEvent class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: Event Result -->
        <b-tab>
          <template #title>
            <BLink
              :to="{ query: { tab: 'event-result' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 4 ? 'active-title' : ''"
              >Event Result</span>
            </BLink>
          </template>

          <EventResult class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: Event Noti Sport -->
        <b-tab>
          <template #title>
            <BLink
              :to="{ query: { tab: 'event-noti' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 5 ? 'active-title' : ''"
              >Event Noti Sport</span>
            </BLink>
          </template>

          <NotiEvent class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: Event Noti Tipping -->
        <b-tab>
          <template #title>
            <BLink
              :to="{ query: { tab: 'event-noti-tipping' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 6 ? 'active-title' : ''"
              >Event Noti Tipping</span>
            </BLink>
          </template>

          <NotiEventTipping class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: Group invite Noti -->
        <b-tab>
          <template #title>
            <BLink
              :to="{ query: { tab: 'group-invite-noti' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 7 ? 'active-title' : ''"
              >Group Invite Noti</span>
            </BLink>
          </template>

          <GroupInviteNoti class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: BACK+ BET -->
        <b-tab>
          <template #title>
            <BLink
              :to="{ query: { tab: 'back+' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 8 ? 'active-title' : ''"
              >Back+ Bet</span>
            </BLink>
          </template>

          <BackBetSetting class="mt-2 pt-75" />
        </b-tab>

      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import LoadingPage from '@core/components/LoadingPage'
import {
  BCard, BLink, BTab, BTabs,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import CompViewTabSetting from './CompViewTabSetting'
import CompViewTabVoucherOffer from './CompViewTabVoucherOffer'
import CompViewTabBonusCash from './CompViewTabBonusCash'
import ListChooseEvent from './ListChooseEvent'
import EventResult from './EventResult'
import NotiEvent from './NotiEvent.vue'
import GroupInviteNoti from './GroupInviteNoti.vue'
import BackBetSetting from './Back+BetSetting.vue'
import NotiEventTipping from './NotiEventTipping.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    LoadingPage,
    BLink,
    CompViewTabSetting,
    CompViewTabVoucherOffer,
    CompViewTabBonusCash,
    ListChooseEvent,
    EventResult,
    NotiEvent,
    GroupInviteNoti,
    BackBetSetting,
    NotiEventTipping,
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
    }
  },
  computed: {
    ...mapState({
    }),

  },

  mounted() {
    switch (this.$route.query.tab) {
      case 'change-password':
        this.tabIndex = 1
        break
      case 'rebuy-token-list':
        this.tabIndex = 2
        break
      case 'event-sport':
        this.tabIndex = 3
        break
      case 'event-result':
        this.tabIndex = 4
        break
      case 'event-noti':
        this.tabIndex = 5
        break
      case 'event-noti-tipping':
        this.tabIndex = 6
        break
      case 'group-invite-noti':
        this.tabIndex = 7
        break
      case 'back+':
        this.tabIndex = 8
        break
      default:
        break
    }
  },
  async created() {
    this.loading = true
    // await this.syncDetail()
    this.loading = false
  },
  methods: {
    ...mapActions({
      // fetchUser: 'user/detail/asyncUser',
      // getNewUserParamsAsync: 'user/newUserParams/getNewUserParamsAsync',
    }),

  },
}
</script>

<style lang="scss" scoped>
.active-title {
  color: white;
}
</style>
