<template>
  <div class="mt-2">

    <!--  Withdrawable-->
    <b-row class="mb-1 d-flex align-items-center">
      <b-col
        cols="3"
        class="font-weight-bold"
      >
        <h4 style="font-weight: bold;">
          Push Noti before:
        </h4>
      </b-col>
      <b-col cols="3">
        <div>{{ currentTimeAmount }} minutes</div>

      </b-col>
      <b-col cols="3">
        <div>Change</div>
        <b-form-input
          id="time-value"
          v-model="timeAmount"
          :no-wheel="true"
        />
      </b-col>

      <b-col cols="3">
        <b-button
          variant="primary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="loadingChangeTime"
          class="btn-set mt-2"
          @click="handleChangeTime"
        >
          <Spinner v-if="loadingChangeTime" />
          <template v-else>
            Apply
          </template>
        </b-button>
      </b-col>
    </b-row>
    <!-- noti -->
    <b-card style="border: 1px solid black;">
      <div class="d-flex align-items-center justify-content-between">
        <h4
          class="mb-1 mr-1"
          style="font-weight: 900;"
        >
          EVENT NOTIFICATION
        </h4>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 ml-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleChangeContentMess"
        >
          <template> Change Content </template>
        </b-button>
      </div>
      <b-row class="ml-2">
        <b-col
          cols="12"
          md="3"
          style="font-weight: bold;"
        >
          Notification Title:
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <p>{{ title }}</p>
        </b-col>
      </b-row>
      <b-row class="ml-2">
        <b-col
          cols="12"
          md="3"
          style="font-weight: bold;"
        >
          Notification Message:
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <p>{{ message }}</p>
        </b-col>
      </b-row>
      <b-row class="ml-2">
        <b-col
          cols="12"
          md="3"
          style="font-weight: bold;"
        >
          Deep Link:
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <p>{{ deepLink }}</p>
        </b-col>
      </b-row>
      <b-row class="ml-2">
        <b-col
          cols="12"
          md="3"
          style="font-weight: bold;"
        >
          Web Link:
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <p>{{ webLink }}</p>
        </b-col>
      </b-row>

    </b-card>
    <!-- CONTENT POPUP -->
    <b-card style="border: 1px solid black;">
      <div class="d-flex align-items-center justify-content-between">
        <h4
          class="mb-1 mr-1"
          style="font-weight: 900;"
        >
          Content flash page:
        </h4>

      </div>
      <div class="d-flex align-items-center">
        <p
          class="mb-0"
          style="font-size: 16px; font-weight: bold; margin-right: 8px;"
        >
          Image:
        </p>
        <b-img
          v-if="contentPopup && contentPopup.png && contentPopup.png.url"
          style="width: 30px; height: 30px; cursor: pointer;"
          :src="contentPopup.png.url"
          alt="logo"
        />
      </div>

      <p style="font-size: 16px;">
        <span style="font-weight: bold;">Title: </span>{{ contentPopup && contentPopup.title && contentPopup.title.value ? contentPopup.title.value : "" }}
      </p>
      <p style="font-size: 16px;">
        <span style="font-weight: bold;">Top text: </span>{{ contentPopup && contentPopup.topText && contentPopup.topText.value ? contentPopup.topText.value : "" }}
      </p>
      <p style="font-size: 16px;">
        <span style="font-weight: bold;">Middle text: </span>{{ contentPopup && contentPopup.middleText && contentPopup.middleText.value ? contentPopup.middleText.value : "" }}
      </p>
      <p style="font-size: 16px;">
        <span style="font-weight: bold;">Bottom text: </span>{{ contentPopup && contentPopup.bottomText && contentPopup.bottomText.value ? contentPopup.bottomText.value : "" }}
      </p>

      <!-- </div> -->
    </b-card>

    <!-- MODAL CHANGE CONTENT POPUP -->
    <b-modal
      ref="content"
      hide-footer
      title="Change Text Content"
      centered
      cancel-variant="outline-secondary"
      size="xl"
      @hide="hideModalContent"
    >
      <div style="color: black;">

        <!-- TITLE NOTI -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Title noti:
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="title"
              type="text"
              placeholder="Add title..."
            />
          </b-col>
        </b-row>
        <!-- MESS NOTI -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Message noti:
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="message"
              type="text"
              placeholder="Add message..."
            />
          </b-col>
        </b-row>
        <!-- DEEPLINK -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Deeplink:
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="deepLink"
              type="text"
              placeholder="Add deeplink..."
            />
          </b-col>
        </b-row>
        <!-- WEBLINK -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Weblink:
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="webLink"
              type="text"
              placeholder="Add weblink..."
            />
          </b-col>
        </b-row>

        <h4 class="mt-2">
          Content FlashPage
        </h4>

        <!-- TITLE -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Title:
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="titleContent"
              type="text"
              placeholder="Add title..."
            />
          </b-col>
        </b-row>

        <!-- Top text -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Top text:
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <!-- <b-form-input
                v-model="topTextContent"
                type="text"
                placeholder="Add top text..."
              /> -->
            <div style="height: 150px; overflow-y: hidden; ">
              <vue-editor
                v-model="topTextContent"
                :editor-options="editorOptions"
                show-preview="true"
                style="height: 100px;"
              />

            </div>
          </b-col>
        </b-row>

        <!-- middle text -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Middle text:
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <!-- <b-form-input
                v-model="middleTextContent"
                type="text"
                placeholder="Add top text..."
              /> -->
            <div style="height: 150px; overflow-y: hidden;">
              <vue-editor
                v-model="middleTextContent"
                :editor-options="editorOptions"
                style="height: 100px;"
              />

            </div>
          </b-col>
        </b-row>
        <!-- bottom text -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Bottom text:
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="bottomTextContent"
              type="text"
              placeholder="Add top text..."
            />
          </b-col>
        </b-row>
        <!-- image -->
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Image:
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <div
              v-if="!imageContent"
              style="cursor: pointer;"
              @click="$refs.refInputEl.$el.click()"
            >
              <feather-icon
                icon="UploadIcon"
                size="16"
                style="margin-left: 8px; "
              /> Upload

            </div>
            <b-img
              v-if="imageContent"
              style="width: 30px; height: 30px; cursor: pointer;"
              :src="imageContent"
              alt="logo"
              @click="$refs.refInputEl.$el.click()"
            />

            <b-form-file
              ref="refInputEl"
              accept=".jpg, .png, .gif, .heic, .jpeg"
              :hidden="true"
              plain
              style="cursor: pointer;"
              @change="inputImageRenderer"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col class="d-flex justify-content-end ">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleSaveChange"
            >
              <Spinner v-if="loadingGetData" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BButton, VBModal, BFormInput, BImg, BFormFile,
} from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import { formatDate } from '@/helpers/utils'
import useToastHandler from '@/services/toastHandler'
import Spinner from '@core/components/Spinner'
import { objectProp } from '@/helpers/props'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    Spinner,
    BFormInput,
    BImg,
    BFormFile,
    VueEditor,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    dataComp: objectProp(),
  },
  data() {
    return {
      contentPopup: null,
      contentNoti: null,

      title: '',
      message: '',
      deepLink: null,
      webLink: null,

      titleContent: '',
      topTextContent: '',
      middleTextContent: '',
      bottomTextContent: '',
      imageContent: '',

      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
            [{ color: [] }],
          ],
        },
        placeholder: 'Add text...',
        theme: 'snow', // or 'bubble'
        formats: [
          'bold', 'italic', 'underline', 'color', 'background',
        ],
        // Set default text color to black (#000000)
        styles: {
          '.ql-editor': {
            color: '#ffffff',
          },
        },
      },
      timeAmount: null,
      loadingChangeTime: false,
      currentTimeAmount: null,

    }
  },
  computed: {
    ...mapState('races/freeBetslip', ['loading', 'total', 'loadingApprove', 'loadingCreate', 'loadingClear']),
    ...mapState('setting/setting', ['loadingGetData']),

  },
  watch: {
    contentNoti(val) {
      this.title = val.title
      this.message = val.message
      this.webLink = val.webLink
      this.deepLink = val.deepLink
      const config = JSON.parse(val.popup.config)
      if (config) {
        this.contentPopup = config
      }
    },
    contentPopup() {
      this.hideModalContent()
    },
  },
  async created() {
    this.contentNoti = await this.getSetting('format-notif-event-upcoming')
    this.currentTimeAmount = await this.getSetting('event-upcoming-send-notif-threshold')
    this.hideModalContent()
  },
  methods: {
    ...mapActions({
      getSetting: 'setting/setting/getSetting',
      uploadFlag: 'sportComp/createComp/uploadFlag',
      setNewSettingNotiEvent: 'setting/setting/setNewSettingNotiEvent',
      setNewSetting: 'setting/setting/setNewSetting',
    }),
    handleChangeContentMess() {
      this.$refs.content.show()
    },
    hideModalContent() {
      this.titleContent = this.contentPopup.title.value
      this.topTextContent = this.contentPopup.topText.value
      this.middleTextContent = this.contentPopup.middleText.value
      this.bottomTextContent = this.contentPopup.bottomText.value
      this.imageContent = this.contentPopup.png.url
    },
    async inputImageRenderer(evt) {
      const formData = new FormData()
      const file = evt.target.files[0]
      if (file && file.size <= 10 * 1024 * 1024) {
        formData.append('image', file)
        const res = await this.uploadFlag(formData)
        if (res) {
          this.imageContent = res
        }
      } else {
        this.showToastError('Error', 'The file is less than or equal to 10MB')
      }
    },
    async handleSaveChange() {
      const value = {
        title: this.title,
        message: this.message,
        deepLink: this.deepLink,
        webLink: this.webLink,
        popup: {
          type: 'NotifEventUpcoming',
          config: {
            deepLink: this.deepLink,
            weblink: this.webLink,
            png: {
              url: this.imageContent,
            },
            title: {
              value: this.titleContent,
            },
            topText: {
              value: this.topTextContent,
            },
            middleText: {
              value: this.middleTextContent,
            },
            bottomText: {
              value: this.bottomTextContent,
            },
          },
        },
      }
      const res = await this.setNewSettingNotiEvent({
        value,
      })
      if (res) {
        this.$refs.content.hide()
        this.showToastSuccess('Success', 'Change content popup successfully')
        this.contentNoti = await this.getSetting('format-notif-event-upcoming')
        this.hideModalContent()
      }
    },
    async  handleChangeTime() {
      this.loadingChangeTime = true
      const res = await this.setNewSetting({ key: 'event-upcoming-send-notif-threshold', number: Number(this.timeAmount) })
      if (res) {
        this.currentTimeAmount = this.timeAmount
      }
      this.loadingChangeTime = false
      this.timeAmount = null
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()
    return {
      formatDate,
      showToastError,
      showToastSuccess,
    }
  },
}
</script>
