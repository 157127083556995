<!-- eslint-disable vue/no-template-shadow -->
<template>
  <section style="color: black !important;">
    <b-card>
      <b-table
        ref="refDetailEvent"
        :items="fetchDetailEvent"
        :fields="tableColumns"
        responsive
        primary-key="racing"
        show-empty
      >
        <template #empty>

          <div
            v-if="fetchDetailEvent.length === 0"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>

        <!-- startTime -->
        <template #cell(startTime)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ formatTime(item.startTime, 'HH:mm aa dd-MM-yyyy') }}
            </div>
          </b-media>
        </template>
        <!-- event -->
        <template #cell(selections)="row">

          <b-button
            size="sm"
            variant="primary"
            @click="row.toggleDetails"
          >
            {{ row.detailsShowing ? 'Hide' : 'Show' }}
          </b-button>
        </template>

        <template #row-details="row">
          <b-card style="border: 1px solid black;">
            <!-- <ul>
              <li
                v-for="(value, key) in row.item.selections"
                :key="key"
              >
                {{ key }}: {{ value.selectionName }}
              </li>
            </ul> -->

            <b-table
              ref="refDetailSelection"
              :items="row.item.selections"
              :fields="tableSelectionColumns"
              responsive
              show-empty
            >

              <!-- Column: edit -->
              <template #cell(edit)="{ item }">
                <div class="d-flex align-items-center font-weight-bold id-column">
                  <div
                    class="d-flex align-items-center font-weight-extrabold"
                    style="cursor: pointer"
                    @click="handleShowModalEdit(item, row.item)"
                  >
                    <feather-icon icon="EditIcon" />
                  </div>
                </div>
              </template></b-table>
          </b-card>
        </template>

      </b-table>

      <!-- MODAL DETAIL EVENT -->
      <b-modal
        ref="edit-selection"
        title="Edit Selection"
        centered
        cancel-variant="outline-secondary"
        size="md"
        hide-footer
      >
        <div style="color: black;">

          <!-- MARKET HANDICAP OR PRIMARY MARKET -->
          <div v-if="selectedMarket && eventItem && (checkShowHandicap() || selectedMarket.name === eventItem.primaryMarketName)">
            <div
              v-for="(item) in selectedMarket.selections"
              :key="item.marketSelectionId"
              style="padding-bottom: 10px; border-bottom: 1px solid black; margin-bottom: 10px;"
            >
              <p>
                <span style="font-weight: bold">Selection Name: </span>  {{ item.selectionName }}
              </p>
              <p>
                <span style="font-weight: bold"> Role:</span>   {{ item.role }}
              </p>

              <!-- RESULT -->
              <b-row class="mt-2">
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <div class="font-weight-bold">
                    Result
                  </div>
                </b-col>
                <b-col
                  cols="8"
                  class="d-flex"
                  style="flex-direction: column"
                >
                  <p
                    v-if="checkShowHandicap()"
                    style="margin: 0;"
                  >
                    Handicap
                  </p>
                  <b-form-radio-group
                    v-else
                    :id="`radio-inline-${item.marketSelectionId}`"
                    v-model="item.result"
                    :options="optionsResult"
                    :name="`radio-inline-${item.marketSelectionId}`"
                  />
                </b-col>
              </b-row>

              <!-- winPriceResult -->
              <b-row class="mt-2">
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <div class="font-weight-bold">
                    winPriceResult
                  </div>
                </b-col>
                <b-col
                  cols="8"
                  class="d-flex"
                  style="flex-direction: column; gap: 12px"
                >
                  <b-form-input
                    v-model="item.winPriceResult"
                  />
                </b-col>
              </b-row>

              <!-- handicap -->
              <b-row
                v-if="checkShowHandicap()"
                class="mt-2"
              >
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <div class="font-weight-bold">
                    handicap
                  </div>
                </b-col>
                <b-col
                  cols="8"
                  class="d-flex"
                  style="flex-direction: column; gap: 12px"
                >
                  <b-form-input
                    v-model="item.handicap"
                  />
                </b-col>
              </b-row>

            </div>

          </div>

          <!-- MARKET NO HANDICAP -->
          <div v-else-if="selectedSelection">
            <div
              style="padding-bottom: 10px; border-bottom: 1px solid black; margin-bottom: 10px;"
            >
              <p>
                <span style="font-weight: bold">Selection Name: </span>  {{ selectedSelection.selectionName }}
              </p>
              <p>
                <span style="font-weight: bold"> Role:</span>   {{ selectedSelection.role }}
              </p>

              <!-- RESULT -->
              <b-row class="mt-2">
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <div class="font-weight-bold">
                    Result
                  </div>
                </b-col>
                <b-col
                  cols="8"
                  class="d-flex"
                  style="flex-direction: column"
                >
                  <p
                    v-if="checkShowHandicap()"
                    style="margin: 0;"
                  >
                    Handicap
                  </p>
                  <b-form-radio-group
                    v-else
                    :id="`radio-inline-${selectedSelection.marketSelectionId}`"
                    v-model="selectedSelection.result"
                    :options="optionsResult"
                    :name="`radio-inline-${selectedSelection.marketSelectionId}`"
                  />
                </b-col>
              </b-row>

              <!-- winPriceResult -->
              <b-row class="mt-2">
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <div class="font-weight-bold">
                    winPriceResult
                  </div>
                </b-col>
                <b-col
                  cols="8"
                  class="d-flex"
                  style="flex-direction: column; gap: 12px"
                >
                  <b-form-input
                    v-model="selectedSelection.winPriceResult"
                  />
                </b-col>
              </b-row>

              <!-- handicap -->
              <b-row
                v-if="checkShowHandicap()"
                class="mt-2"
              >
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <div class="font-weight-bold">
                    handicap
                  </div>
                </b-col>
                <b-col
                  cols="8"
                  class="d-flex"
                  style="flex-direction: column; gap: 12px"
                >
                  <b-form-input
                    v-model="selectedSelection.handicap"
                  />
                </b-col>
              </b-row>

            </div>
            <small
              v-if="errorResult"
              class="text-danger"
            >{{ errorResult }}</small>
          </div>

        </div>

        <!-- handicap market -->
        <!-- <b-row
          v-if="checkShowHandicap()"
          class="mt-2"
          style="color: black !important;"
        >
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              handicap market
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column; gap: 12px"
          >
            <b-form-input
              v-model="handicapMarket"
            />
          </b-col>
        </b-row>
        <small
          v-if="errorHandicap"
          class="text-danger"
        >{{ errorHandicapMarket }}</small> -->

        <small
          v-if="errorHandicap"
          class="text-danger"
        >{{ errorHandicap }}</small>

        <!-- handicap result -->
        <b-row
          v-if="checkShowHandicap()"
          class="mt-2"
          style="color: black !important;"
        >
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              handicapResult
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column; gap: 12px"
          >
            <b-form-input
              v-model="handicapResult"
            />
          </b-col>
        </b-row>
        <small
          v-if="errorHandicapResult"
          class="text-danger"
        >{{ errorHandicapResult }}</small>

        <b-row>
          <b-col class="d-flex justify-content-end mt-2">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleEditSelection"
            >
              <Spinner v-if="loadingChangeSelection" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-card>
  </section>
</template>

<script>
import {
  BTable,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BMedia,
  BFormRadioGroup,
} from 'bootstrap-vue'

import { mapActions, mapState } from 'vuex'
import useToastHandler from '@/services/toastHandler'
import { objectProp } from '@/helpers/props'
import { computed, ref, watch } from '@vue/composition-api'
import { formatTime } from '@/helpers/utils'
import Spinner from '@core/components/Spinner'

export default {
  components: {
    BTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BFormRadioGroup,
    BMedia,
    Spinner,
  },
  props: {
    eventItem: objectProp(),
  },

  data() {
    return {
      result: '',
      errorResult: '',
      winPrice: null,
      errorWinPrice: '',

      selectedSelection: null,
      selectedMarket: null,
      handicap: null,
      handicapMarket: null,
      handicapResult: null,
      errorHandicap: '',
      // errorHandicapMarket: '',
      errorHandicapResult: '',

      optionsResult: [
        { text: 'Win', value: 'Win' },
        { text: 'Lose', value: 'Lose' },
      ],

    }
  },
  computed: {
    ...mapState('sportComp/eventDetail', ['loading', 'loadingChangeSelection']),
  },
  watch: {
    winPrice(val) {
      if (val) this.errorWinPrice = ''
    },
    result(val) {
      if (val) this.errorResult = ''
    },
    handicap(val) {
      if (val) this.errorHandicap = ''
    },
    // handicapMarket(val) {
    //   if (val) this.errorHandicapMarket = ''
    // },
    handicapResult(val) {
      if (val) this.errorHandicapResult = ''
    },
  },
  methods: {
    ...mapActions({
      getListMarketEventDetail: 'sportComp/eventDetail/getListMarketEventDetail',
      changeSelection: 'sportComp/eventDetail/changeSelection',
      getListMarketResult: 'sportComp/eventDetail/getListMarketResult',
    }),
    async fetchDetailEvent() {
      const data = await this.getListMarketEventDetail({
        page: this.currentPage,
        limit: this.perPage,
        eventId: this.eventItem.eventId,
      })
      const res = await this.getListMarketResult({
        eventId: this.eventItem.eventId,

      })
      const newList = data.data.map(i => {
        const market = res.data.markets
          .find(mar => mar.externalID === i.marketId)
        const selections = i.selections.map(sel => {
          const selection = market.selections.find(s => s.externalID === sel.marketSelectionId)
          return {
            ...sel,
            handicap: selection.handicap,

          }
        })
        return {
          ...i,
          handicap: market.handicap,
          handicapResult: market.handicapResult,
          selections,
        }
      })
      this.totalMarket = data.pagination.total
      return newList
    },
    handleShowModalEdit(item, market) {
      this.selectedSelection = item
      this.selectedMarket = market
      this.$refs['edit-selection'].show()
      // this.selectedType = item.resultType
      this.winPrice = item.winPriceResult
      this.result = item.result
      this.handicapMarket = this.selectedMarket.handicap
      this.handicap = this.selectedSelection.handicap
      this.handicapResult = this.selectedMarket.handicapResult
    },
    async handleEditSelection() {
      if (this.checkShowHandicap()) {
        if (!this.handicap) this.errorHandicap = 'Handicap field is requied!'
        // if (!this.handicapMarket) this.errorHandicapMarket = 'Handicap market field is requied!'
        if (!this.handicapResult) this.errorHandicapResult = 'Handicap result field is requied!'
        if (this.errorHandicap || this.errorHandicapResult) return
      }
      if (this.selectedMarket.name === this.eventItem.primaryMarketName) {
        if (this.selectedMarket.selections.some(i => !i.result || !i.winPriceResult)) { return this.errorResult = 'The field result and winPrice are required!' }
        this.errorResult = ''
        const data = {
          EventID: this.eventItem.eventId,
          Markets: [
            {
              ExternalID: this.selectedMarket.marketId,
              MarketType: this.selectedMarket.marketType,
              isPrimaryMarket: this.selectedMarket.name === this.eventItem.primaryMarketName,
              // ...(this.checkShowHandicap() && { Handicap: Number(this.handicapMarket) }),
              ...(this.checkShowHandicap() && { HandicapResult: Number(this.handicapResult) }),
              Selections: this.selectedMarket.selections.map(i => ({
                ExternalID: i.marketSelectionId,
                Role: i.role,
                WinPrice: Number(i.winPriceResult),
                // Result: 'Handicap',
                //   Handicap: Number(i.handicap),
                Result: this.checkShowHandicap() ? 'Handicap' : i.result,
                ...(this.checkShowHandicap() && { Handicap: Number(i.handicap) }),
              })),

            },
          ],
          isFinished: this.selectedMarket.name === this.eventItem.primaryMarketName,
        }
        const res = await this.changeSelection(data)
        if (res) {
          this.showToastSuccess('Success', 'Update data selection successfully!')
          this.refetchData()
          this.handicap = null
          this.handicapMarket = null
          this.handicapResult = null
          this.$refs['edit-selection'].hide()
        }
        return
      }
      if (this.errorResult || this.errorWinPrice) return
      if (this.checkShowHandicap()) return this.saveResultHandicap()
      const data = {
        EventID: this.eventItem.eventId,
        Markets: [
          {
            ExternalID: this.selectedMarket.marketId,
            MarketType: this.selectedMarket.marketType,
            isPrimaryMarket: this.selectedMarket.name === this.eventItem.primaryMarketName,
            // ...(this.checkShowHandicap() && { Handicap: Number(this.handicapMarket) }),
            ...(this.checkShowHandicap() && { HandicapResult: Number(this.handicapResult) }),

            Selections: [
              {
                ExternalID: this.selectedSelection.marketSelectionId,
                Role: this.selectedSelection.role,
                WinPrice: Number(this.selectedSelection.winPriceResult),
                Result: this.checkShowHandicap() ? 'Handicap' : this.selectedSelection.result,
                ...(this.checkShowHandicap() && { Handicap: Number(this.selectedSelection.handicap) }),

              },
            ],
          },
        ],
        isFinished: this.selectedMarket.name === this.eventItem.primaryMarketName,
      }
      const res = await this.changeSelection(data)
      if (res) {
        this.showToastSuccess('Success', 'Update data selection successfully!')
        this.refetchData()
        this.handicap = null
        this.handicapMarket = null
        this.handicapResult = null
        this.$refs['edit-selection'].hide()
      }
    },
    async saveResultHandicap() {
      const data = {
        EventID: this.eventItem.eventId,
        Markets: [
          {
            ExternalID: this.selectedMarket.marketId,
            MarketType: this.selectedMarket.marketType,
            isPrimaryMarket: this.selectedMarket.name === this.eventItem.primaryMarketName,
            ...(this.checkShowHandicap() && { HandicapResult: Number(this.handicapResult) }),
            ...(this.checkShowHandicap() && { Handicap: Number(this.handicapMarket) }),

            Selections: this.selectedMarket.selections.map(i => ({
              ExternalID: i.marketSelectionId,
              Role: i.role,
              WinPrice: Number(i.winPriceResult),
              Result: 'Handicap',
              Handicap: Number(i.handicap),
            })),

          },
        ],
        isFinished: this.selectedMarket.name === this.eventItem.primaryMarketName,
      }
      const res = await this.changeSelection(data)
      if (res) {
        this.showToastSuccess('Success', 'Update data selection successfully!')
        this.refetchData()
        this.handicap = null
        this.handicapMarket = null
        this.handicapResult = null
        this.$refs['edit-selection'].hide()
      }
    },
    checkShowHandicap() {
      return this.selectedMarket && (this.selectedMarket.marketType === 'Line' || this.selectedMarket.marketType === 'Totals' || this.selectedMarket.marketType === 'FlatTotal')
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    const perPage = ref(10)
    const refDetailEvent = ref(null)
    const currentPage = ref(1)
    const totalMarket = ref(0)

    const tableColumns = [
      {
        key: 'marketId',
      },
      {
        key: 'marketType',
      },
      {
        key: 'name',
      },
      { key: 'handicap' },
      {
        key: 'handicapResult',
      },
      { key: 'startTime' },
      { key: 'selections' },
    ]

    const tableSelectionColumns = [
      { key: 'marketSelectionId' },
      { key: 'selectionName' },
      { key: 'role' },
      { key: 'result' },
      { key: 'resultType' },
      { key: 'winPrice' },
      { key: 'winPriceResult' },
      { key: 'handicap' },
      { key: 'edit' },
    ]

    const refetchData = () => {
      refDetailEvent.value.refresh()
    }

    watch([currentPage, perPage], () => {
      refetchData()
    })

    const dataMeta = computed(() => {
      const localItemsCount = refDetailEvent.value ? refDetailEvent.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalMarket.value,
      }
    })

    return {
      refDetailEvent,

      showToastSuccess,
      showToastError,
      perPage,
      currentPage,

      refetchData,
      dataMeta,

      tableColumns,
      tableSelectionColumns,

      formatTime,
    }
  },
}
</script>
