<template>
  <div class="mt-2">
    <!-- Table Container Card -->
    <b-card>

      <!-- BACK+ BET SETTING -->
      <div class="d-flex align-items-center my-2">
        <feather-icon
          icon="SettingsIcon"
          size="18"
        />
        <h4 class="mb-0 ml-50">
          Back+ Bet Settings:
        </h4>
      </div>

      <b-row class="mt-2 mb-2">
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            Maximum Bet Yourself Odds
          </div>
        </b-col>
        <b-col cols="2">
          <div>Current</div>
          <b-form-input
            id="voucher-limit"
            :value="formatCurrencyBuiltInFunc(currentLimitOdd)"
            readonly
          />
        </b-col>

        <b-col cols="5">
          <b-row>
            <b-col
              cols="7"
              class="col-deposit"
            >
              <div>Change</div>
              <b-form-input
                v-model="limitOdd"
                :formatter="formatNumberPrize"
              />
            </b-col>

            <b-button
              variant="primary"
              :disabled="loadingChangeLimitOdd"
              class="btn-set ml-2 mt-2"
              @click="handleChangeLimitOdd"
            >
              <Spinner v-if="loadingChangeLimitOdd" />
              <template v-else>
                Apply
              </template>
            </b-button>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="d-flex justify-content-end mb-2">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="handleShowModalConfig"
          >
            <!-- <Spinner v-if="loadingChangeSelection" /> -->
            <span>Add Config Profile</span>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="refEventTable"
        :items="fetchEvent"
        :fields="tableColumns"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="fetchEvent.length === 0"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>

        <!-- limitEstReturns -->
        <template #cell(limitEstReturns)="{ item }">
          <div
            v-for="x in formatDataEstReturn(item)"
            :key="x.id"
            class="d-flex align-items-center font-weight-bold"
          >
            Any {{ x.id }}: ${{ x.limit }}
          </div>
        </template>
        <!-- PLACE BET -->
        <template #cell(isDisablePlaceBet)="{ item }">
          <div class="d-flex align-items-center font-weight-bold">
            <b-badge
              pill
              :variant="formatColorType(!item.isDisablePlaceBet)"
            >
              {{ !item.isDisablePlaceBet ? 'Yes': 'No' }}
            </b-badge>
          </div>
        </template>
        <!-- AUTO ACCEPT -->
        <template #cell(isAutoAccept)="{ item }">
          <div class="d-flex align-items-center font-weight-bold">
            <b-badge
              pill
              :variant="formatColorType(item.isAutoAccept)"
            >
              {{ item.isAutoAccept ? 'Yes': 'No' }}
            </b-badge>
          </div>
        </template>
        <!-- Column: action -->
        <template #cell(action)="{ item }">
          <div
            class="d-flex align-items-center font-weight-bold id-column"
          >
            <b-dropdown
              variant="link"
              no-caret
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="handleEdit(item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="showModal('delete', item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>

            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} items
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProfile"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <!--  ACTION MODAL -->
      <b-modal
        ref="modal"
        :title="modal.title"
        centered
        cancel-variant="outline-secondary"
        @ok="handleSubmitModal"
      >
        <b-card-text class="text-title">
          {{ modal.content }}
        </b-card-text>
        <template #modal-ok>
          <Spinner v-if="loadingSetting" />
          <span v-else>Submit</span>
        </template>
      </b-modal>
    </b-card>

    <!-- ADD NEW CONFIG -->

    <b-modal
      ref="config"
      title="Config Profile"
      centered
      hide-footer
      cancel-variant="outline-secondary"
      size="md"
      @hide="hideModal"
    >
      <section style="color: black">
        <div>

          <!-- profile -->
          <b-row class="mt-2">
            <b-col
              cols="5"
              class="d-flex align-items-center"
            >
              <div class="font-weight-bold">
                Profile Type
              </div>
            </b-col>
            <b-col
              cols="6"
              class="d-flex"
            >
              <div v-if="isEdit">
                {{ selectedProfile }}
              </div>
              <b-form-select
                v-else
                v-model="selectedProfile"
                :options="clientProfileList"
              />
            </b-col>
          </b-row>

          <!--  Auto Accept -->
          <b-row class="mt-2">
            <b-col
              cols="5"
              class="d-flex align-items-center"
            >
              <div class="font-weight-bold">
                Auto Accept
              </div>
            </b-col>
            <b-col
              cols="6"
              class="d-flex"
            >
              <b-form-radio-group
                id="auto-accept"
                v-model="selectedAutoAccept"
                :options="optionsAutoAccept"
                name="auto-accept"
              />
            </b-col>
          </b-row>

          <!-- PLACE BET -->
          <b-row class="mt-2">
            <b-col
              cols="5"
              class="d-flex align-items-center"
            >
              <div class="font-weight-bold">
                Place Bet
              </div>
            </b-col>
            <b-col
              cols="6"
              class="d-flex"
              style="flex-direction: column"
            >
              <b-form-radio-group
                id="place-bet"
                v-model="selectedPlaceBet"
                :options="optionsPlaceBet"
                name="place-bet"
              />
            </b-col>
          </b-row>

          <!--  LIMIT EST -->
          <b-row class="mt-2">
            <b-col
              cols="5"
              class="d-flex align-items-center"
            >
              <div class="font-weight-bold">
                Limit Est Return
              </div>
            </b-col>
            <b-col
              cols="7"
              class="d-flex"
              style="flex-direction: column"
            >
              <b-row
                v-for="item in limitEstReturns"
                :key="item.id"
                style="margin-bottom: 4px;"
                class="d-flex align-items-center"
              >
                <b-col cols="4">
                  <div class="font-weight-bold">
                    Any {{ item.id }}
                  </div>
                </b-col>
                <b-col cols="8">

                  <b-form-input
                    v-model="item.limit"
                    placeholder="Enter est.return"
                    :formatter="formatNumberPrize"
                  />
                </b-col>

              </b-row>
            </b-col>
          </b-row>
        </div>

        <b-row>
          <b-col class="d-flex justify-content-end mt-2">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="loadingSetting"
              @click="handleSaveNewConfig"
            >
              <Spinner v-if="loadingSetting" />
              <template v-else>
                Save
              </template>
            </b-button>
          </b-col>
        </b-row>
      </section>
    </b-modal>

  </div>
</template>

<script>
import {
  BTable,
  BPagination,
  BRow,
  BCol,
  BCard,
  VBModal,
  BDropdown,
  BDropdownItem,
  BBadge,
  BCardText,
  BButton,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
} from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import {
  formatDate, formatDateTimeLocal, formatTime, formatDateSend, formatCurrencyBuiltInFunc,
} from '@/helpers/utils'
import useToastHandler from '@/services/toastHandler'
import { arrayProp } from '@/helpers/props'
import { computed, ref, watch } from '@vue/composition-api'
import Spinner from '@core/components/Spinner'
import { addDays, subDays } from 'date-fns'

export default {
  components: {
    BTable,
    BPagination,
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
    BBadge,
    BCardText,
    BButton,
    BFormInput,
    BFormRadioGroup,
    BFormSelect,
    Spinner,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    entriesList: arrayProp(),
  },
  data() {
    return {
      originData: null,
      action: {
        type: '',
        id: '',
      },
      modal: {
        title: '',
        content: '',
      },
      selectedProfile: null,
      originEstReturn: [
        {
          id: 0, limit: null,
        },
        { id: 2, limit: null },
        { id: 3, limit: null },
        { id: 4, limit: null },
        { id: 5, limit: null },
        { id: 6, limit: null },
        { id: 7, limit: null },
        { id: 8, limit: null },
        { id: 9, limit: null },
      ],
      limitEstReturns: [
        {
          id: 0, limit: null,
        },
        { id: 2, limit: null },
        { id: 3, limit: null },
        { id: 4, limit: null },
        { id: 5, limit: null },
        { id: 6, limit: null },
        { id: 7, limit: null },
        { id: 8, limit: null },
        { id: 9, limit: null },
      ],
      optionsPlaceBet: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      selectedPlaceBet: false,
      optionsAutoAccept: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      selectedAutoAccept: false,
      loadingSetting: false,
      isEdit: false,
      currentLimitOdd: null,
      limitOdd: null,
      loadingChangeLimitOdd: false,
    }
  },
  computed: {
    ...mapState('races/setting', ['clientProfileList']),

  },
  watch: {
    selectedSport() {
      this.currentPage = 1
      this.refetchData()
    },
  },
  async mounted() {
    await this.getProfileType()
    this.currentLimitOdd = await this.getSetting('maximum-bet-yourself-odds')
  },
  methods: {
    ...mapActions({
      getSetting: 'setting/setting/getSetting',
      setNewSettingUpdateProfileBetBack: 'setting/setting/setNewSettingUpdateProfileBetBack',
      getProfileType: 'races/setting/getProfileType',
      setNewSetting: 'setting/setting/setNewSetting',
    }),
    formatColorType(val) {
      if (val) return 'light-success'
      return 'light-warning'
    },
    async fetchEvent() {
      const res = await this.getSetting('config-bet-yourself-betslip')
      const data = res.configs
      this.originData = this.convertKeysToUpperCase(data)
      const result = Object.keys(data).map(key => {
        const item = data[key]
        return {
          type: key.toUpperCase(),
          limitEstReturns: item.limitEstReturns || 0,
          isDisablePlaceBet: item.isDisablePlaceBet || false,
          isAutoAccept: item.isAutoAccept || false,
        }
      })
      this.totalProfile = result.length
      return result
    },
    convertKeysToUpperCase(obj) {
      const newObj = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          const upperCaseKey = key.toUpperCase()
          newObj[upperCaseKey] = obj[key]
        }
      }
      return newObj
    },
    formatter(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }

        if (Number(value) > this.totalPage) return this.totalPage
        if (Number(value) < 1) return '1'
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },

    handleShowModalDetail(item) {
      this.eventIdSelected = item
      this.$refs['detail-event'].show()
    },
    handleShowModalScore(item) {
      this.eventIdSelected = item
      const home = item.scores.find(i => i.role === 'HomeTeam')
      this.homeScore = home.score
      this.homeTeam = home.team
      const away = item.scores.find(i => i.role === 'AwayTeam')
      this.awayScore = away.score
      this.awayTeam = away.team
      this.$refs.scores.show()
    },
    setModal(title, content) {
      this.modal.title = title
      this.modal.content = content
    },
    showModal(type, id) {
      this.action = { id, type }
      if (type === 'delete') {
        this.setModal('Delete', `Are you sure you want to delete the setting of profile ${id.type}?`)
      }

      this.$refs.modal.show()
    },
    async handleSubmitModal(event) {
      event.preventDefault()
      const { type, id } = this.action
      if (type === 'delete') {
        this.loadingSetting = true

        const config = { ...this.originData }
        delete config[id.type]
        const data = {
          value: {
            configs: {
              ...config,
            },
          },
        }
        const res = await this.setNewSettingUpdateProfileBetBack({ key: 'config-bet-yourself-betslip', data })
        if (res) {
          this.refetchData()
          this.$refs.modal.hide()
          this.showToastSuccess('Success', 'Delete this config successfully')
        }
        this.loadingSetting = false
      }
    },
    hideModal() {
      this.selectedProfile = null
      this.limitEstReturns = this.originEstReturn
      this.selectedAutoAccept = false
      this.selectedPlaceBet = false

      this.isEdit = false
    },
    handleShowModalConfig() {
      this.$refs.config.show()
    },
    formatNumberPrize(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    async handleSaveNewConfig(event) {
      event.preventDefault()
      const formatListEst = {}
      this.limitEstReturns.forEach(item => {
        if (item.limit) formatListEst[item.id] = Number(item.limit)
      })

      if (!this.selectedProfile) return this.showToastError('Error', 'Please add data!')
      this.loadingSetting = true
      const data = {
        value: {
          configs: {
            ...this.originData,
            [this.selectedProfile.toUpperCase()]: {
              limitEstReturns: formatListEst,
              isAutoAccept: this.selectedAutoAccept,
              isDisablePlaceBet: !this.selectedPlaceBet,
            },
          },
        },
      }
      const res = await this.setNewSettingUpdateProfileBetBack({ key: 'config-bet-yourself-betslip', data })
      if (res) {
        this.refetchData()
        this.$refs.config.hide()
        this.showToastSuccess('Success', 'Save this config successfully')
      }
      this.loadingSetting = false
    },
    handleEdit(item) {
      this.isEdit = true
      this.selectedProfile = item.type

      const result = Object.keys(item.limitEstReturns).map(key => ({
        id: Number(key),
        limit: item.limitEstReturns[key],
      }))
      const list = this.originEstReturn.map(i => {
        const selecItem = result.find(rel => rel.id === i.id)
        if (selecItem) {
          return {
            ...i,
            limit: selecItem.limit,
          }
        }
        return i
      })
      this.limitEstReturns = list
      this.selectedAutoAccept = item.isAutoAccept
      this.selectedPlaceBet = !item.isDisablePlaceBet
      this.$refs.config.show()
    },
    async handleChangeLimitOdd() {
      if (!this.limitOdd) return
      this.loadingChangeLimitOdd = true
      const res = await this.setNewSetting({ key: 'maximum-bet-yourself-odds', number: Number(this.limitOdd) })
      if (res) {
        this.currentLimitOdd = this.limitOdd
      }
      this.loadingChangeLimitOdd = false
      this.limitOdd = null
    },
    formatDataEstReturn(item) {
      if (typeof item.limitEstReturns === 'number') return item.limitEstReturns
      const result = Object.keys(item.limitEstReturns).map(key => ({
        id: Number(key),
        limit: item.limitEstReturns[key],
      }))
      return result
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()

    const refEventTable = ref(null)

    // Table Handlers
    const tableColumns = [
      {
        key: 'type',
      },
      {
        key: 'limitEstReturns',

      },
      {
        key: 'isDisablePlaceBet',
        label: 'Place bet',
      },
      {
        key: 'isAutoAccept',
        label: 'Auto accept',
      },
      { key: 'action' },
    ]

    const perPage = ref(10)
    const totalProfile = ref(0)
    const totalRaces = ref(0)
    const currentPage = ref(1)

    const startDateFilter = ref(subDays(new Date(), 30))
    const endDateFilter = ref(addDays(new Date(), 30))

    const refetchData = () => {
      refEventTable.value.refresh()
    }

    const dataMeta = computed(() => {
      const localItemsCount = refEventTable.value ? refEventTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalProfile.value,
      }
    })

    watch([currentPage, perPage], () => {
      refetchData()
    })

    watch([endDateFilter, startDateFilter], () => {
      if (endDateFilter && startDateFilter) {
        refetchData()
      }
    })

    return {
      perPage,
      totalProfile,
      dataMeta,

      formatDate,
      formatDateTimeLocal,
      showToastError,
      tableColumns,
      currentPage,
      totalRaces,
      refEventTable,
      refetchData,

      formatTime,

      showToastSuccess,
      formatDateSend,

      startDateFilter,
      endDateFilter,

      formatCurrencyBuiltInFunc,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .id-column {
    max-width: 150px;
    word-break: break-word;
  }
  .text-title {
    color: black;
    font-weight: 500;
  }
  </style>
