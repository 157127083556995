<template>
  <div class="mt-2">
    <!-- Table Container Card -->
    <b-card>
      <b-row class="ml-1 mb-2">
        <b-form-radio-group
          id="get-prize"
          v-model="selectedSport"
          :options="optionsSport"
          name="radio-inline"
        />
      </b-row>

      <b-row class="mt-2 mb-2">
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2 pr-2 d-flex align-items-center justify-content-center"
        >
          From:
          <b-form-datepicker
            id="startDateFilter"
            v-model="startDateFilter"
            class="mx-2"
            locale="en-US"
            placeholder="Start date"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2 pr-2 d-flex align-items-center justify-content-center"
        >
          To:
          <b-form-datepicker
            id="endDateFilter"
            v-model="endDateFilter"
            class="mx-2"
            locale="en-US"
            placeholder="End date"
          />
        </b-col>
      </b-row>

      <b-row class="d-flex align-items-center justify-content-end ">
        <!-- Search -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1 mb-2"
            placeholder="Search eventId..."
          />
        </b-col>

        <b-table
          ref="refEventTable"
          :items="fetchEvent"
          :fields="tableColumns"
          responsive
          show-empty
          primary-key="id"
        >
          <template #empty>
            <div
              v-if="fetchEvent.length === 0"
              class="text-center my-2"
            >
              No matching records found
            </div>
          </template>
          <!-- event -->
          <template #cell(event)="{ item }">
            <b-media vertical-align="center">
              <div class="font-weight-bold d-block text-nowrap text-body cursor-pointer">
                {{ item.meetingName }}
              </div>
            </b-media>
          </template>
          <!-- raceNumber -->
          <template #cell(raceNumber)="{ item }">
            <b-media vertical-align="center">
              <div class="font-weight-bold d-block text-nowrap text-body cursor-pointer">
                {{ item.number }}
              </div>
            </b-media>
          </template>
          <!-- raceName -->
          <template #cell(raceName)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center font-weight-bold">
                {{ item.name }}
              </div>
            </b-media>
          </template>
          <!-- raceName -->
          <template #cell(date)="{ item }">
            <b-media vertical-align="center">
              <div
                class="d-flex align-items-center font-weight-bold"
                style="width: 90px"
              >
                {{ formatTime(item.startTime, 'dd-MM-yyyy') }}
              </div>
            </b-media>
          </template>
          <!-- time -->
          <template #cell(time)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center font-weight-bold">
                {{ formatTime(item.startTime) }}
              </div>
            </b-media>
          </template>
          <!-- Column: isDisableScore -->
          <template #cell(isDisableScore)="{ item }">
            <b-media vertical-align="center">
              <b-badge
                pill
                :variant="formatColorType(item.isDisableScore)"
              >
                {{ item.isDisableScore ? 'Disable' : 'Enable' }}
              </b-badge>
            </b-media>
          </template>
          <!-- Column: action -->
          <template #cell(action)="{ item }">
            <div class="d-flex align-items-center font-weight-bold id-column">
              <b-dropdown
                variant="link"
                no-caret
                boundary="viewport"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="handleShowModalDetail(item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Results</span>
                </b-dropdown-item>
                <b-dropdown-item @click="handleShowModalScore(item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Scores</span>
                </b-dropdown-item>
                <b-dropdown-item @click="handleShowModalOdds(item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Odds</span>
                </b-dropdown-item>

                <b-dropdown-item @click="showModal('Input result automatically', item)">
                  <feather-icon
                    icon="CheckCircleIcon"
                  />
                  <span class="align-middle ml-50">Input result automatically</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showModal('recalculate', item)">
                  <feather-icon
                    icon="CheckCircleIcon"
                  />
                  <span class="align-middle ml-50">Recalculate user totalPoint</span>
                </b-dropdown-item>

                <b-dropdown-item @click="showModal('crawl-score', item)">
                  <feather-icon
                    v-if="item.isDisableScore"
                    icon="UnlockIcon"
                  />
                  <feather-icon
                    v-else
                    icon="LockIcon"
                  />

                  <span class="align-middle ml-50">{{ item.isDisableScore ? 'Enable crawl score' : 'Disable crawl score' }}</span>
                </b-dropdown-item>

              </b-dropdown>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalSelectedEvent"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-row></b-card>

    <!-- MODAL DETAIL EVENT -->
    <b-modal
      ref="detail-event"
      title="List Market"
      centered
      cancel-variant="outline-secondary"
      hide-footer
      size="xl"
    >
      <DetailEvent :event-item="eventIdSelected" />
      <template #modal-ok>
        <span>Save</span>
      </template>
    </b-modal>

    <!--  ACTION MODAL -->
    <b-modal
      ref="modal"
      :title="modal.title"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="loadingInputResultAuto"
      @ok="handleSubmitModal"
    >
      <b-card-text class="text-title">
        {{ modal.content }}
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="loadingInputResultAuto" />
        <span v-else>Submit</span>
      </template>
    </b-modal>

    <!--  SCORE MODAL -->
    <b-modal
      ref="scores"
      title="Edit Scores"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="loadingInputResultAuto"
      @ok="handleSubmitModalChangeScore"
    >
      <b-row class="mt-2 text-title">
        <b-col
          cols="8"
          class="d-flex align-items-center"
        >
          <div>
            Team Home: <span style="font-weight: 900;">{{ homeTeam }}</span>
          </div>
        </b-col>
        <b-col
          cols="4"
          class="d-flex"
          style="flex-direction: column"
        >
          <b-form-input
            v-model="homeScore"
            type="text"
            placeholder="Add score..."
          />
        </b-col>
      </b-row>
      <b-row class="mt-2 text-title">
        <b-col
          cols="8"
          class="d-flex align-items-center"
        >
          <div class="font-weight-bold">
            Team Away: <span style="font-weight: 900;">{{ awayTeam }}</span>
          </div>
        </b-col>
        <b-col
          cols="4"
          class="d-flex"
          style="flex-direction: column"
        >
          <b-form-input
            v-model="awayScore"
            type="text"
            placeholder="Add score..."
          />
        </b-col>
      </b-row>

      <template #modal-ok>
        <Spinner v-if="loadingInputResultAuto" />
        <span v-else>Submit</span>
      </template>
    </b-modal>

    <!-- MODAL DETAIL EVENT -->
    <b-modal
      ref="edit-result"
      title="Update Result"
      centered
      cancel-variant="outline-secondary"
      size="md"
      hide-footer
    >
      <div
        v-if="selectedMarket && selectedMarket.selections"
        style="color: black;"
      >

        <div
          v-for="item in selectedMarket.selections"
          :key="item.marketSelectionId"
          style="padding-bottom: 10px; border-bottom: 1px solid black; margin-bottom: 10px;"
        >
          <p>
            <span style="font-weight: bold">Selection Name: </span>  {{ item.selectionName }}
          </p>
          <p>
            <span style="font-weight: bold"> Role:</span>   {{ item.role }}
          </p>

          <!-- RESULT -->
          <b-row class="mt-2">
            <b-col
              cols="3"
              class="d-flex align-items-center"
            >
              <div class="font-weight-bold">
                Result
              </div>
            </b-col>
            <b-col
              cols="8"
              class="d-flex"
              style="flex-direction: column"
            >
              <b-form-radio-group
                :id="`radio-inline-${item.role}`"
                v-model="item.result"
                :options="optionsResult"
                :name="`radio-inline-${item.role}`"
              />
            </b-col>
          </b-row>

          <!-- winPriceResult -->
          <b-row class="mt-2">
            <b-col
              cols="3"
              class="d-flex align-items-center"
            >
              <div class="font-weight-bold">
                winPriceResult
              </div>
            </b-col>
            <b-col
              cols="8"
              class="d-flex"
              style="flex-direction: column; gap: 12px"
            >
              <b-form-input
                v-model="item.winPriceResult"
                :formatter="formatPrice"
              />
            </b-col>
          </b-row>

        </div>
        <small
          v-if="errorResult"
          class="text-danger"
        >{{ errorResult }}</small>

      </div>

      <b-row>
        <b-col class="d-flex justify-content-end mt-2">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="handleEditSelection"
          >
            <Spinner v-if="loadingChangeSelection" />
            <span v-else>Save</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- MODAL UPDATE ODDS -->
    <b-modal
      ref="edit-selection"
      title="Update Odds"
      centered
      cancel-variant="outline-secondary"
      size="md"
      hide-footer
    >
      <div
        v-if="selectedOddsItem && selectedOddsItem.selections"
        style="color: black;"
      >

        <div
          v-for="item in selectedOddsItem.selections"
          :key="item.marketSelectionId"
          style="padding-bottom: 10px; border-bottom: 1px solid black; margin-bottom: 10px;"
        >
          <p>
            <span style="font-weight: bold">Selection Name: </span>  {{ item.selectionName }}
          </p>
          <p>
            <span style="font-weight: bold"> Role:</span>   {{ item.selectionRole }}
          </p>

          <!-- winPriceResult -->
          <b-row class="">
            <b-col
              cols="3"
              class="d-flex align-items-center"
            >
              <div class="font-weight-bold">
                winPrice
              </div>
            </b-col>
            <b-col
              cols="8"
              class="d-flex"
              style="flex-direction: column; gap: 12px"
            >
              <b-form-input
                v-model="item.originalWinPrice"
                :formatter="formatPrice"
              />
            </b-col>
          </b-row>

        </div>
      </div>

      <b-row>
        <b-col class="d-flex justify-content-end mt-2">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="handleSaveNewOdds"
          >
            <Spinner v-if="loadingUpdateOdd" />
            <span v-else>Save</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BPagination,
  BRow,
  BCol,
  BCard,
  VBModal,
  BFormDatepicker,
  BMedia,
  BFormRadioGroup,
  BDropdown,
  BDropdownItem,
  BCardText,
  BFormInput,
  BButton,
  BBadge,
} from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import {
  formatDate, formatDateTimeLocal, formatTime, formatDateSend,
} from '@/helpers/utils'
import useToastHandler from '@/services/toastHandler'
import { arrayProp } from '@/helpers/props'
import { computed, ref, watch } from '@vue/composition-api'
// import Spinner from '@core/components/Spinner'
import { addDays, subDays } from 'date-fns'
import { debounce } from 'debounce'
import Spinner from '@core/components/Spinner'
import DetailEvent from './DetailEvent.vue'

export default {
  components: {
    BTable,
    BPagination,
    BRow,
    BCol,
    BCard,
    BMedia,
    BFormRadioGroup,
    BFormDatepicker,
    DetailEvent,
    BDropdown,
    BDropdownItem,
    BCardText,
    BFormInput,
    BButton,
    Spinner,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    entriesList: arrayProp(),
  },
  data() {
    return {
      groups: [],
      searchText: '',
      selectedGroups: [],
      page: 1,
      totalListAll: null,
      totalPage: 1,
      timeout: null,
      eventDeleteId: null,

      listSelectedEvent: [],
      listAllEventId: [],
      optionsSport: [],
      selectedSport: 'AFL',
      eventIdSelected: null,

      action: {
        type: '',
        id: '',
      },
      modal: {
        title: '',
        content: '',
      },
      homeScore: '',
      homeTeam: '',
      awayScore: '',
      awayTeam: '',
      selectedMarket: null,
      errorResult: '',

      selectedResult: null,

      optionsResult: [
        { text: 'Win', value: 'Win' },
        { text: 'Lose', value: 'Lose' },
      ],
      selectedOddsItem: null,
    }
  },
  computed: {
    ...mapState('sportComp/listEvent', ['loadingAdd']),
    ...mapState('sportComp/eventDetail', ['loadingInputResultAuto']),
    ...mapState('sportComp/eventDetail', ['loading', 'loadingChangeSelection']),
    ...mapState('tipping/create', ['loadingUpdateOdd']),
  },
  watch: {
    selectedSport() {
      this.currentPage = 1
      this.refetchData()
    },
  },
  async created() {
    const res = await this.getListLeaguesSport()
    if (Array.isArray(res.data)) {
      const formatList = res.data.map(i => ({
        text: i.sportLeagueId,
        value: i.sportLeagueId,
        sportCode: i.sportCode,
      }))
      this.optionsSport = formatList
    }
  },
  methods: {
    ...mapActions({
      getListSelectedEvent: 'sportComp/listEvent/getListSelectedEvent',
      getListAllEventInDB: 'sportComp/listEvent/getListAllEventInDB',
      setAddEvent: 'sportComp/listEvent/setAddEvent',
      inputResultAuto: 'sportComp/eventDetail/inputResultAuto',
      recalculateUserTotalPoint: 'sportComp/eventDetail/recalculateUserTotalPoint',
      changeFlagEvent: 'sportComp/updateComp/changeFlagEvent',
      getListLeaguesSport: 'sportComp/listEvent/getListLeaguesSport',
      getMarketOfEvent: 'tipping/create/getMarketOfEvent',
      changeSelection: 'sportComp/eventDetail/changeSelection',
      updateNewOdds: 'tipping/create/updateNewOdds',
      changeStatusCrawlScore: 'sportComp/eventDetail/changeStatusCrawlScore',
    }),
    async fetchEvent() {
      const data = await this.getListAllEventInDB({
        page: this.currentPage,
        limit: this.perPage,
        league: this.selectedSport,
        ...(this.startDateFilter && this.endDateFilter && { time: this.startDateFilter }),
        ...(this.endDateFilter && this.endDateFilter && { endTime: this.endDateFilter }),
        eventId: this.searchQuery,
      })
      this.totalSelectedEvent = data.pagination.total
      return data.data
    },
    formatter(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }

        if (Number(value) > this.totalPage) return this.totalPage
        if (Number(value) < 1) return '1'
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },

    async handleShowModalDetail(item) {
      const res = await this.getMarketOfEvent(item.eventId)
      this.selectedMarket = res
      this.eventIdSelected = item
      if (item.eventId.includes('fake')) {
        return this.$refs['edit-result'].show()
      }
      this.$refs['detail-event'].show()
    },
    handleShowModalScore(item) {
      this.eventIdSelected = item
      const home = item.scores.find(i => i.role === 'HomeTeam')
      this.homeScore = home.score
      this.homeTeam = home.team
      const away = item.scores.find(i => i.role === 'AwayTeam')
      this.awayScore = away.score
      this.awayTeam = away.team
      this.$refs.scores.show()
    },
    setModal(title, content) {
      this.modal.title = title
      this.modal.content = content
    },
    showModal(type, id) {
      this.action = { id, type }
      if (type === 'Input result automatically') {
        this.setModal('Input result automatically', `Are you sure you want to Input result automatically of event ${id.eventId}?`)
      }
      if (type === 'recalculate') {
        this.setModal('Recalculate', `Are you sure you want to recalculate user totalPoint of event ${id.eventId}?`)
      }
      if (type === 'crawl-score') {
        this.setModal(id.isDisableScore ? 'Enable Crawl Score' : 'Disable Crawl Score', `Are you sure you want to ${id.isDisableScore ? 'enable' : 'disable'} crawl score of event ${id.eventId}?`)
      }

      this.$refs.modal.show()
    },
    async handleSubmitModal(event) {
      event.preventDefault()
      const { id, type } = this.action

      if (type === 'Input result automatically') {
        const result = await this.inputResultAuto(id.eventId)
        if (!result) return
        this.showToastSuccess('Success', 'Input result automatically successfully!')
      }
      if (type === 'recalculate') {
        const result = await this.recalculateUserTotalPoint(id.eventId)
        if (!result) return
        this.showToastSuccess('Success', 'Input result automatically successfully!')
      }

      if (type === 'crawl-score') {
        const result = await this.changeStatusCrawlScore(id.eventId)
        if (!result) return
        this.showToastSuccess('Success', 'Change status crawl score successfully!')
        this.refetchData()
      }

      if (!this.loadingInputResultAuto) {
        this.$nextTick(() => {
          this.$refs.modal.hide()
        })
      }
    },
    async handleSubmitModalChangeScore() {
      const scores = [
        { role: 'HomeTeam', team: this.homeTeam, score: this.homeScore },
        { role: 'AwayTeam', team: this.awayTeam, score: this.awayScore },
      ]

      const res = await this.changeFlagEvent({
        eventId: this.eventIdSelected.eventId,
        data: {
          scores,
        },
      })
      if (res) {
        this.showToastSuccess('Success', 'Input result automatically successfully!')
        this.refetchData()
      }
    },
    async handleEditSelection() {
      if (this.selectedMarket.selections.some(i => !i.result || !i.winPriceResult)) return this.showToastError('Error', 'Please fill all data!')
      const data = {
        EventID: this.eventIdSelected.eventId,
        Markets: [
          {
            ExternalID: this.selectedMarket.marketId,
            MarketType: this.selectedMarket.marketType,
            isPrimaryMarket: this.selectedMarket.name === this.eventIdSelected.primaryMarketName,
            Selections: this.selectedMarket.selections.map(se => ({
              ExternalID: se.marketSelectionId,
              Role: se.role,
              WinPrice: Number(se.winPriceResult),
              Result: se.result,

            })),

          },
        ],
        isFinished: true,
      }
      const res = await this.changeSelection(data)
      if (res) {
        this.showToastSuccess('Success', 'Update data selection successfully!')
        this.refetchData()
        this.$refs['edit-result'].hide()
      }
    },
    formatPrice(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    async handleShowModalOdds(item) {
      const res = await this.getMarketOfEvent(item.eventId)
      this.selectedOddsItem = res
      this.$refs['edit-selection'].show()
    },
    async handleSaveNewOdds() {
      if (!this.selectedOddsItem) return
      const res = await this.updateNewOdds({
        marketSelections: this.selectedOddsItem.selections.map(i => ({
          marketSelectionId: i.marketSelectionId,
          originalWinPrice: i.originalWinPrice,
        })),
      })
      if (res) {
        this.showToastSuccess('Success', 'Update data selection successfully!')
        this.$refs['edit-selection'].hide()
      }
    },
    formatColorType(val) {
      if (!val) return 'light-success'
      return 'light-danger'
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()

    const refEventTable = ref(null)

    // Table Handlers
    const tableColumns = [
      {
        key: 'eventId',
      },
      {
        key: 'eventTitle',
        label: 'Event name',
      },
      {
        key: 'date',
      },
      {
        key: 'time',
        label: 'START TIME',
      },
      {
        key: 'league',
      },
      {
        key: 'isDisableScore', label: ' crawl score',
      },
      { key: 'action' },
    ]

    const perPage = ref(10)
    const totalSelectedEvent = ref(0)
    const currentPage = ref(1)
    const searchQuery = ref(null)

    const startDateFilter = ref(subDays(new Date(), 30))
    const endDateFilter = ref(addDays(new Date(), 30))

    const refetchData = () => {
      refEventTable.value.refresh()
    }

    const dataMeta = computed(() => {
      const localItemsCount = refEventTable.value ? refEventTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalSelectedEvent.value,
      }
    })

    watch([currentPage, perPage], () => {
      refetchData()
    })

    watch([endDateFilter, startDateFilter], () => {
      if (endDateFilter && startDateFilter) {
        refetchData()
      }
    })

    watch(
      [searchQuery],
      debounce(() => {
        totalSelectedEvent.value = 0
        refetchData()
      // eslint-disable-next-line comma-dangle
      }, 700)
    )

    return {
      perPage,
      totalSelectedEvent,
      dataMeta,

      formatDate,
      formatDateTimeLocal,
      showToastError,
      tableColumns,
      currentPage,
      refEventTable,
      refetchData,

      formatTime,

      showToastSuccess,
      formatDateSend,

      startDateFilter,
      endDateFilter,

      searchQuery,
    }
  },
}
</script>

<style lang="scss" scoped>
.id-column {
  max-width: 150px;
  word-break: break-word;
}
.text-title {
  color: black;
  font-weight: 500;
}
</style>
