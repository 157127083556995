<template>
  <b-table
    ref="refCampaignListTable"
    :items="fetchCampaigns"
    :fields="tableColumns"
    responsive
    show-empty
    primary-key="campaign"
  >
    <template #empty>
      <div
        v-if="loadingListCampaign"
        class="text-center text-dark my-2"
      >
        <p>Loading... Please wait</p>
      </div>

      <div
        v-else-if="!loadingListCampaign && fetchCampaigns.length === 0"
        class="text-center text-dark my-2"
      >
        No matching records found
      </div>
    </template>

    <!-- Column: deposit amount -->
    <template #cell(depositAmount)="{ item }">
      <div class="d-flex align-items-center font-weight-bold id-column">
        <div class="d-flex align-items-center font-weight-extrabold">
          {{ item.depositAmount !== 0 ? formatCurrencyBuiltInFunc(item.depositAmount) : '' }}
        </div>
      </div>
    </template>

    <!-- Column: depositBonusCouponAmount -->
    <template #cell(depositBonusCouponAmount)="{ item }">
      <div class="d-flex align-items-center font-weight-bold id-column">
        <div class="d-flex align-items-center font-weight-extrabold">
          {{ item.depositBonusCouponAmount !== 0 ?item.depositBonusCouponAmount : '' }}
        </div>
      </div>
    </template>
    <!-- Column: purchaseBetSlip -->
    <template #cell(purchaseBetSlip)="{ item }">
      <div class="d-flex align-items-center font-weight-bold id-column">
        <div class="d-flex align-items-center font-weight-extrabold">
          {{ item.purchaseBetSlip !== 0 ?item.purchaseBetSlip : '' }}
        </div>
      </div>
    </template>
    <!-- Column: purchaseBonusCouponAmount -->
    <template #cell(purchaseBonusCouponAmount)="{ item }">
      <div class="d-flex align-items-center font-weight-bold id-column">
        <div class="d-flex align-items-center font-weight-extrabold">
          {{ item.purchaseBonusCouponAmount !== 0 ?item.purchaseBonusCouponAmount : '' }}
        </div>
      </div>
    </template>
    <!-- Column: depositExpiredDays -->
    <template #cell(depositExpiredDays)="{ item }">
      <div class="d-flex align-items-center font-weight-bold id-column">
        <div class="d-flex align-items-center font-weight-extrabold">
          {{ item.depositExpiredDays !== 0 ?item.depositExpiredDays : '' }}
        </div>
      </div>
    </template>
    <!-- Column: purchaseExpiredDays -->
    <template #cell(purchaseExpiredDays)="{ item }">
      <div class="d-flex align-items-center font-weight-bold id-column">
        <div class="d-flex align-items-center font-weight-extrabold">
          {{ item.purchaseExpiredDays !== 0 ?item.purchaseExpiredDays : '' }}
        </div>
      </div>
    </template>

    <!-- Column: status -->
    <template #cell(status)="{ item }">
      <div class="d-flex align-items-center font-weight-bold id-column">
        <b-badge
          pill
          :variant="resolveCampaignVariant(item.status)"
        >
          {{ item.status }}
        </b-badge>
      </div>
    </template>

    <!-- Column: action -->
    <template #cell(action)="{ item }">
      <div class="d-flex align-items-center font-weight-bold id-column">
        <!-- <div
          style="cursor: pointer;"
          class="d-flex align-items-center font-weight-extrabold"
          @click="handleShowEditModal(item)"
        >
          <feather-icon
            icon="EditIcon"
            size="16"
            class="align-middle text-body"
          />
        </div> -->
        <b-dropdown
          variant="link"
          no-caret
          boundary="viewport"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item @click="handleShowEditModal(item)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item @click="showModal(item)">
            <feather-icon
              icon="TrashIcon"
            />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>

        </b-dropdown>
      </div>
    </template>

  </b-table>

</template>

<script>
import { getUserData } from '@/auth/utils'
import { isRoleSuperAdmin } from '@/helpers/permission'
import useToastHandler from '@/services/toastHandler'
import { avatarText } from '@core/utils/filter'
import {
  BTable,
  VBModal, BDropdown,
  BBadge,
  BDropdownItem,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
// import ExportCSV from './ExportCSV.vue'
import {
  formatDate, formatCurrencyBuiltInFunc, formatDateTimeLocal, formatDateTimeTracking,
} from '@/helpers/utils'
import { formatDistanceStrict } from 'date-fns'
import { ref } from '@vue/composition-api'
import { stringProp } from '@/helpers/props'

export default {
  components: {
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    statusFilter: stringProp(),
    typeFilter: stringProp(),
    userProfileFilter: stringProp(),
  },
  data() {
    return {
      deleteId: null,
      tableColumns: [
        { key: 'name' },
        { key: 'type' },
        { key: 'depositAmount', label: 'deposit amount' },
        { key: 'depositBonusCouponAmount', label: 'Bonus Deposit' },
        { key: 'depositExpiredDays', label: 'Deposit Expired day' },
        { key: 'purchaseBetSlip', label: 'Purchase entries' },
        { key: 'purchaseBonusCouponAmount', label: 'Bonus Purchase' },
        { key: 'purchaseExpiredDays', label: 'Purchase Expired day' },
        { key: 'userProfile', label: 'Customer profile' },
        { key: 'status' },
        { key: 'action' },
      ],
    }
  },
  computed: {
    ...mapState('races/listCampaign', ['listCampaign', 'loadingListCampaign', 'isCreateSuccess']),

    isLoading() {
      return this[this.action.type].loading
    },

    isSubmitting() {
      return this.loading || this.loadingClientProfile
    },
    checkTimeout() {
      return breakTime => {
        if (breakTime) {
          const time = new Date(breakTime).getTime() - new Date().getTime()
          if (time > 0) {
            return `${formatDistanceStrict(new Date(breakTime), new Date())}`
          }
          return null
        }
        return null
      }
    },
  },
  watch: {
    isCreateSuccess(val) {
      if (val === true) {
        this.checkCreateCampaign(false)
        this.refCampaignListTable.refresh()
      }
    },
    statusFilter() {
      this.refCampaignListTable.refresh()
    },
    typeFilter() {
      this.refCampaignListTable.refresh()
    },
    userProfileFilter() {
      this.refCampaignListTable.refresh()
    },
  },

  methods: {
    ...mapActions({
      getListCampaign: 'races/listCampaign/getListCampaign',
      checkCreateCampaign: 'races/listCampaign/checkCreateCampaign',
      deleteCampaign: 'races/listCampaign/deleteCampaign',
    }),
    async fetchCampaigns() {
      const data = await this.getListCampaign({
        ...(this.typeFilter && { type: this.typeFilter.toLowerCase() }),
        ...(this.statusFilter && { status: this.statusFilter.toLowerCase() }),
        ...(this.userProfileFilter && { userProfile: this.userProfileFilter === 'NOT RATED' ? 'NOT_RATED' : this.userProfileFilter }),
      })

      if (!data && this.list.error) return this.showToastError(false, 'Error fetching users list')
      return data
    },
    handleShowEditModal(item) {
      this.$emit('update-data', item)
    },
    showModal(item) {
      this.$emit('show-modal', item)
    },
    resolveCampaignVariant(status) {
      if (status === 'active') return 'light-success'
      return 'light-warning'
    },

  },
  setup() {
    const refCampaignListTable = ref(null)
    const { showToastError, showToastSuccess } = useToastHandler()

    return {
      getUserData,

      // Filter
      avatarText,

      showToastError,
      showToastSuccess,

      isRoleSuperAdmin,
      formatDate,
      formatCurrencyBuiltInFunc,
      formatDateTimeLocal,
      formatDateTimeTracking,
      formatDistanceStrict,
      refCampaignListTable,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.id-column {
  max-width: 300px;
  min-width: 120px;
  word-break: break-word;
}
.text-title {
  color: black;
  font-weight: 500;
}
</style>
