<template>
  <section>

    <b-card>
      <div class="d-flex align-items-center my-2">
        <h4 class="mb-0 ml-50">
          Voucher Offers:
        </h4>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 ml-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleAddNewCampaign"
        >
          <!-- <Spinner v-if="loadingSaveVourcherSetting" /> -->
          <template> Create New Campaign </template>
        </b-button>
      </div>

      <b-row
        class="mt-2"
        style="width: 100%;"
      >
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            @input="onChangeStatus"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Type</label>
          <v-select
            :value="typeFilter"
            :options="typeOptions"
            class="w-100"
            @input="onChangeType"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>User profile</label>
          <v-select
            :value="userProfileFilter"
            :options="clientProfileList"
            class="w-100"
            @input="onChangeUserProfile"
          />
        </b-col>
      </b-row>

      <b-row
        class="mt-2"
        style="width: 100%;"
      >
        <CampaignTable
          :status-filter="statusFilter"
          :type-filter="typeFilter"
          :user-profile-filter="userProfileFilter"
          @show-modal="showModal"
          @update-data="handleUpdateCampaign"
        />
      </b-row>
    </b-card>

    <b-modal
      ref="modal"
      :title="modalType === 'add' ? 'Add Campaign' : 'Edit Campaign'"
      centered
      hide-footer
      cancel-variant="outline-secondary"
      size="md"
      @hide="hideModal"
      @ok="handleSubmitModal"
    >
      <section style="color: black">
        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div
              class="font-weight-bold"
            >
              Campaign Offer name
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="campaignName"
              type="text"
              placeholder="Add name..."
            />
          </b-col>
        </b-row>
        <small
          v-if="errorName"
          class="text-danger"
        >{{ errorName }}</small>

        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Type
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column; gap: 12px;"
          >
            <v-select
              v-model="chooseValueType"
              :options="optionsType"
              style="width: 180px"
            />

          </b-col>
        </b-row>
        <small
          v-if="errorType"
          class="text-danger"
        >{{ errorType }}</small>

        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Deposit
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column; gap: 12px;"
          >
            <b-row>

              <b-col
                cols="3"
              >
                <div class="font-weight-bold">
                  Amount
                </div>
              </b-col>
              <b-col
                cols="9"
              >
                <b-form-input
                  v-model="depositValue"
                  type="number"
                  placeholder="$50"
                  :no-wheel="true"
                  :formatter="formatPrice"
                  style="width: 100px; margin-left: 8px; text-align: center"
                />
              </b-col>
            </b-row>
            <b-row>

              <b-col
                cols="3"
              >
                <div class="font-weight-bold">
                  Receive Bonus
                </div>
              </b-col>
              <b-col
                cols="9"
              >
                <b-form-input
                  v-model="depositBonusCouponAmount"
                  style="width: 100px; margin-left: 8px; text-align: center"
                  type="number"
                  :no-wheel="true"
                />
              </b-col>
            </b-row>
            <b-row style="border-bottom: 1px solid grey; padding-bottom: 12px;">

              <b-col
                cols="3"
              >
                <div class="font-weight-bold">
                  Expired days
                </div>
              </b-col>
              <b-col
                cols="9"
              >
                <b-form-input
                  v-model="depositExpiredDays"
                  style="width: 100px; margin-left: 8px; text-align: center"
                  type="number"
                  :no-wheel="true"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <small
          v-if="errorDeposit"
          class="text-danger"
        >{{ errorDeposit }}</small>

        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Buy entries
            </div>
          </b-col>
          <b-col
            cols="8"
            style="display: flex; flex-direction: column; gap: 12px"
          >
            <b-row>

              <b-col
                cols="3"
              >
                <div class="font-weight-bold">
                  Purchase
                </div>
              </b-col>
              <b-col
                cols="9"
              >
                <b-form-input
                  v-model="purchaseValue"
                  style="width: 100px; margin-left: 8px; text-align: center"
                  type="number"
                  :no-wheel="true"
                />
              </b-col>
            </b-row>
            <b-row>

              <b-col
                cols="3"
              >
                <div class="font-weight-bold">
                  Receive Bonus
                </div>
              </b-col>
              <b-col
                cols="9"
              >
                <b-form-input
                  v-model="purchaseBonusCouponAmount"
                  style="width: 100px; margin-left: 8px; text-align: center"
                  type="number"
                  :no-wheel="true"
                />
              </b-col>
            </b-row>
            <b-row style="border-bottom: 1px solid grey; padding-bottom: 12px;">

              <b-col
                cols="3"
              >
                <div class="font-weight-bold">
                  Expired days
                </div>
              </b-col>
              <b-col
                cols="9"
              >
                <b-form-input
                  v-model="purchaseExpiredDays"
                  style="width: 100px; margin-left: 8px; text-align: center"
                  type="number"
                  :no-wheel="true"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <small
          v-if="errorPurchase"
          class="text-danger"
        >{{ errorPurchase }}</small>

        <b-row class="mt-2">
          <b-col
            cols="3"
            class="d-flex"
            style="flex-direction: column"
          >
            <div class="font-weight-bold">
              Apply to Profile
            </div>
          </b-col>
          <b-col
            cols="8"
            class="d-flex"
            style="flex-direction: column"
          >
            <ChooseCustomProfile
              :value="listProfile.value"
              :type-number="listProfile.id"
              :client-profile-list="clientProfileList"
              @update-data="handleUpdateTypeProfile"
            />
          </b-col>
        </b-row>
        <small
          v-if="errorChooseProfile"
          class="text-danger"
        >{{ errorChooseProfile }}</small>
        <b-row>
          <b-col class="d-flex justify-content-end mt-4">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="loadingAddCampaign"
              @click="handleSubmitModal"
            >
              <Spinner v-if="loadingAddCampaign" />
              <template v-else>
                {{ modalType === 'add' ? ' Create Campaign' : "Save" }}
              </template>
            </b-button>
          </b-col>
        </b-row>
      </section>
    </b-modal>
    <b-modal
      ref="delete-campaign"
      title="Delete Campaign"
      centered
      cancel-variant="outline-secondary"
      @ok="handleSubmitDelete"
    >
      <div style="color: black;">
        Are you sure you want to delete this campaign?
      </div>
      <template #modal-ok>
        <span>Submit</span>
      </template>
    </b-modal>
  </section>
</template>

<script>
import {
  BCol, BRow, BButton, BCard, BFormInput,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import {
  formatDateQuery, formatCurrencyBuiltInFunc,
} from '@/helpers/utils'
import Spinner from '@core/components/Spinner'
import vSelect from 'vue-select'
import useToastHandler from '@/services/toastHandler'
import ChooseCustomProfile from './ChooseCustomProfile'
import CampaignTable from './CampaignTable'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BFormInput,
    BButton,
    Spinner,
    ChooseCustomProfile,
    // BFormCheckbox,
    CampaignTable,
    vSelect,
  },
  data() {
    return {
      listProfile: { id: 1, value: '' },
      selectedProfileList: [],

      optionsType: [
        { label: 'Voucher', value: 'voucher' },
        { label: 'Rebuy token', value: 'rebuy-token' },
      ],
      chooseValueType: null,
      campaignName: '',
      purchaseExpiredDays: null,
      depositExpiredDays: null,
      depositBonusCouponAmount: null,
      purchaseBonusCouponAmount: null,
      customerProfile: null,
      purchaseValue: null,
      depositValue: null,

      errorName: '',
      errorType: '',
      errorDeposit: '',
      errorPurchase: '',
      errorChooseProfile: '',
      loadingAddCampaign: false,
      modalType: 'add',
      couponCampaignId: null,
      deleteId: null,

      statusOptions: ['Active', 'Disable'],
      statusFilter: null,
      typeOptions: ['Voucher', 'Rebuy-token'],
      typeFilter: null,
      userProfileFilter: null,
    }
  },
  watch: {
    campaignName(val) {
      if (val) this.errorName = ''
    },
    chooseValueType(val) {
      if (val) this.errorType = ''
    },
    listProfile(val) {
      if (val) this.errorChooseProfile = ''
    },
    depositExpiredDays(val) {
      if (val >= 0 && this.depositBonusCouponAmount >= 0 && this.depositValue >= 0) this.errorDeposit = ''
    },
    depositBonusCouponAmount(val) {
      if (val >= 0 && this.depositExpiredDays >= 0 && this.depositValue >= 0) this.errorDeposit = ''
    },
    depositValue(val) {
      if (val >= 0 && this.depositExpiredDays >= 0 && this.depositBonusCouponAmount >= 0) this.errorDeposit = ''
    },
    purchaseValue(val) {
      if (val && this.purchaseBonusCouponAmount && this.purchaseExpiredDays) this.errorPurchase = ''
    },
    purchaseBonusCouponAmount(val) {
      if (val && this.purchaseValue && this.purchaseExpiredDays) this.errorPurchase = ''
    },
    purchaseExpiredDays(val) {
      if (val && this.purchaseValue && this.purchaseBonusCouponAmount) this.errorPurchase = ''
    },
  },
  computed: {
    ...mapState('races/listCampaign', ['loadingCreateCampaign']),
    ...mapState('races/setting', ['clientProfileList']),

  },
  async mounted() {
    await this.getProfileType()
  },
  methods: {
    ...mapActions({
      createCampaign: 'races/listCampaign/createCampaign',
      updateCampaign: 'races/listCampaign/updateCampaign',
      checkCreateCampaign: 'races/listCampaign/checkCreateCampaign',
      deleteCampaign: 'races/listCampaign/deleteCampaign',
      getProfileType: 'races/setting/getProfileType',
    }),
    formatPrice(value) {
      if (value < 0) return null
      return value
    },
    handleUpdateTypeProfile(data) {
      if (data.type === 'add') {
        this.listProfile.value = data.value
        this.customerProfile = data.value
        this.errorChooseProfile = ''
      }
      if (data.type === 'remove') {
        this.listProfile.value = ''
        this.customerProfile = ''
      }
    },
    handleAddNewCampaign() {
      this.modalType = 'add'
      this.$refs.modal.show()
    },
    async handleSubmitModal(event) {
      event.preventDefault()
      if (!this.campaignName) this.errorName = 'Campaign name is required'
      if (!this.chooseValueType) this.errorType = 'Type is required'
      if (this.depositValue === null || this.depositExpiredDays === null || this.depositBonusCouponAmount === null) this.errorDeposit = 'Deposit fields is required'
      if (this.purchaseValue === null || this.purchaseExpiredDays === null || this.purchaseBonusCouponAmount === null) this.errorPurchase = 'Purchase field is required'
      if (!this.customerProfile) this.errorChooseProfile = 'Customer profile is required'
      if (this.errorName || this.errorType || this.errorDeposit || this.errorPurchase || this.errorChooseProfile) return
      if (this.modalType === 'edit') {
        const newData = {
          couponCampaignId: this.couponCampaignId,
          name: this.campaignName,
          type: this.chooseValueType.value === 'voucher' ? 'voucher' : 'rebuy-token',
          userProfile: this.customerProfile === 'NOT RATED' ? 'NOT_RATED' : this.customerProfile,
          purchaseBetSlip: this.purchaseValue,
          purchaseBonusCouponAmount: this.purchaseBonusCouponAmount,
          purchaseExpiredDays: this.purchaseExpiredDays,
          depositAmount: this.depositValue,
          depositExpiredDays: this.depositExpiredDays,
          depositBonusCouponAmount: this.depositBonusCouponAmount,
        }

        const res = await this.updateCampaign(newData)
        if (res.data) {
          this.checkCreateCampaign(true)
          this.showToastSuccess('Success', 'Update campaign successfully!')
        }
        this.$refs.modal.hide()
        this.resetModal()
      }
      if (this.modalType !== 'add') return

      const data = {
        name: this.campaignName,
        type: this.chooseValueType.value === 'voucher' ? 'voucher' : 'rebuy-token',
        userProfile: this.customerProfile === 'NOT RATED' ? 'NOT_RATED' : this.customerProfile,
        purchaseBetSlip: this.purchaseValue,
        purchaseBonusCouponAmount: this.purchaseBonusCouponAmount,
        purchaseExpiredDays: this.purchaseExpiredDays,
        depositAmount: this.depositValue,
        depositExpiredDays: this.depositExpiredDays,
        depositBonusCouponAmount: this.depositBonusCouponAmount,
      }

      const res = await this.createCampaign(data)
      if (res.data) {
        this.checkCreateCampaign(true)
        this.showToastSuccess('Success', 'Create campaign successfully!')
      }
      this.$refs.modal.hide()
      this.resetModal()
    },
    hideModal() {
      this.$refs.modal.hide()
      if (this.modalType === 'edit') this.resetModal()
    },
    resetModal() {
      this.chooseValueType = null
      this.campaignName = ''
      this.purchaseExpiredDays = null
      this.depositExpiredDays = null
      this.depositBonusCouponAmount = null
      this.purchaseBonusCouponAmount = null
      this.customerProfile = null
      this.purchaseValue = null
      this.depositValue = null
      this.listProfile.value = ''
      this.errorName = ''
      this.errorType = ''
      this.errorDeposit = ''
      this.errorPurchase = ''
      this.errorChooseProfile = ''
    },
    async handleUpdateCampaign(data) {
      this.modalType = 'edit'
      this.couponCampaignId = data.couponCampaignId
      this.campaignName = data.name
      this.chooseValueType = this.optionsType.find(i => i.value === data.type)
      this.depositValue = data.depositAmount
      this.depositExpiredDays = data.depositExpiredDays
      this.depositBonusCouponAmount = data.depositBonusCouponAmount
      this.purchaseValue = data.purchaseBetSlip
      this.purchaseBonusCouponAmount = data.purchaseBonusCouponAmount
      this.purchaseExpiredDays = data.purchaseExpiredDays
      this.customerProfile = data.userProfile
      this.listProfile.value = data.userProfile
      this.$refs.modal.show()
    },
    showModal(item) {
      this.$refs['delete-campaign'].show()
      this.deleteId = item.couponCampaignId
    },
    async handleSubmitDelete() {
      const res = await this.deleteCampaign(this.deleteId)
      if (res) {
        this.showToastSuccess('Success', 'Delete campaign successfully!')
        this.checkCreateCampaign(true)
        this.$nextTick(() => {
          this.$refs['delete-campaign'].hide()
        })
        this.deleteId = null
      } else {
        this.showToastError(false, 'Error delete this campaign')
      }
    },
    onChangeStatus(selected) {
      this.statusFilter = selected
    },
    onChangeType(selected) {
      this.typeFilter = selected
    },
    onChangeUserProfile(selected) {
      this.userProfileFilter = selected
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()
    return {
      showToastSuccess,
      showToastError,
      formatDateQuery,
      formatCurrencyBuiltInFunc,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .btn-set {
    height: fit-content;
  }
  .add-btn {
    padding: 2px 4px;
  }

  .remove-btn {
    padding: 0px 4px 2px 4px;
    margin-left: 1px;
  }

  .delete-btn {
    color: red;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .v-select .vs__dropdown-toggle,
  .v-select .vs__search {
    cursor: pointer;
  }
  @import '@core/scss/vue/libs/vue-select.scss';

  .ml-14 {
    margin-left: 14px;
  }
  </style>
