<template>
  <section>
    <b-card>
      <div class="d-flex align-items-center my-2">
        <h4 class="mb-0 ml-50">Bonus Cash Offers:</h4>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 ml-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleAddNewOffer"
        >
          <template> Create New Offer </template>
        </b-button>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 ml-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleAddOfferFullCashBetslip"
        >
          <template> Create Full Cash Betslip Offer </template>
        </b-button>
      </div>

      <b-row class="mt-2" style="width: 100%">
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Status</label>
          <v-select :value="statusFilter" :options="statusOptions" class="w-100" @input="onChangeStatus" />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Sort Field</label>
          <v-select :value="fieldFilter" :options="fieldOptions" class="w-100" @input="onChangeField" />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Sort Order</label>
          <v-select :value="orderFilter" :options="orderOptions" class="w-100" @input="onChangeOrder" />
        </b-col>
      </b-row>

      <b-row class="mt-2" style="width: 100%">
        <BonusCashTable
          :status-filter="statusFilter"
          :field-filter="fieldFilter"
          :order-filter="orderFilter"
          @show-modal="showModal"
          @update-data="handleUpdateOffer"
          @show-list-user="handleShowUserList"
          @type="checkType"
          @add-direct="handleAddDirect"
        />
      </b-row>
    </b-card>

    <b-modal
      ref="modal"
      :title="modalType === 'add' ? 'Add Offer' : 'Edit Offer'"
      centered
      hide-footer
      cancel-variant="outline-secondary"
      size="md"
      @hide="hideModal"
      @ok="handleSubmitModal"
    >
      <section style="color: black">
        <!-- OFFER NAME -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Offer name</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column">
            <b-form-input v-model="offerName" type="text" placeholder="Add name..." />
          </b-col>
        </b-row>
        <small v-if="errorName" class="text-danger">{{ errorName }}</small>

        <!-- DESCRIPTION -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Description</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column">
            <b-form-input v-model="description" type="text" placeholder="Add description..." />
          </b-col>
        </b-row>
        <small v-if="errorDescription" class="text-danger">{{ errorDescription }}</small>

        <!-- Type -->
        <b-row v-if="modalType === 'add'" class="mb-2 mt-1 d-flex align-items-start">
          <b-col cols="3" class="font-weight-bold">
            <span>Type </span>
          </b-col>
          <b-col cols="9" style="display: flex; gap: 4px; align-items: center">
            <b-form-radio-group
              v-model="selectedType"
              :options="optionsType"
              name="radio-inline"
              style="display: flex"
            />
          </b-col>
        </b-row>

        <b-row v-if="modalType === 'add' && selectedType === 'UserProfile'" class="mt-2">
          <b-col cols="3" class="d-flex" style="flex-direction: column">
            <div class="font-weight-bold">Apply to Profile</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column">
            <ChooseListUserProfile
              :value="listProfile.value"
              :type-number="listProfile.id"
              :client-profile-list="clientProfileList"
              @update-data="handleUpdateTypeProfile"
            />
          </b-col>
        </b-row>
        <b-row v-if="modalType === 'add' && (selectedType === 'UserId' || selectedType === 'direct')" class="mt-2">
          <b-col cols="3" class="d-flex" style="flex-direction: column">
            <div class="font-weight-bold">Apply to User</div>
            <small v-if="selectedType === 'direct'">(Optional)</small>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column">
            <ChooseListUser
              @update-data="handleAddUserList"
              @upload-file="handleGetDataUpload"
              :modalType="modalType"
            />
          </b-col>
        </b-row>
        <small v-if="errorChooseProfile" class="text-danger">{{ errorChooseProfile }}</small>

        <!-- BONUS AMOUNT DIRECT -->
        <b-row v-if="selectedType === 'direct' && modalType === 'add'" class="mt-2">
          <b-col cols="3">
            <div class="font-weight-bold">Amount</div>
            <small v-if="selectedType === 'direct'">(Optional)</small>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <b-form-input
              v-model="bonusAmount"
              type="number"
              placeholder="$50"
              :no-wheel="true"
              :formatter="formatPrice"
            />
          </b-col>
        </b-row>
        <!-- <small v-if="errorBonusAmount" class="text-danger">{{ errorBonusAmount }}</small> -->

        <!-- BONUS PERCENT -->
        <b-row
          v-if="(selectedType !== 'direct' && !offerEdit) || (offerEdit && offerEdit.type !== 'direct')"
          class="mt-2"
        >
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Bonus percent</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <b-form-input v-model="bonusPercent" placeholder="50%" type="number" :no-wheel="true" />
          </b-col>
        </b-row>
        <small v-if="errorBonusPercent" class="text-danger">{{ errorBonusPercent }}</small>

        <!-- BONUS LIMIT -->
        <b-row
          v-if="(selectedType !== 'direct' && !offerEdit) || (offerEdit && offerEdit.type !== 'direct')"
          class="mt-2"
        >
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Bonus limit</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <b-form-input
              v-model="bonusLimit"
              type="number"
              placeholder="$50"
              :no-wheel="true"
              :formatter="formatPrice"
            />
          </b-col>
        </b-row>
        <small v-if="errorBonusLimit" class="text-danger">{{ errorBonusLimit }}</small>

        <!-- OPEN AT -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Open at</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <flat-pickr
              v-model="openDate"
              class="form-control"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i:s',
                enableSeconds: true,
                static: true,
              }"
            />
          </b-col>
        </b-row>
        <small v-if="errorOpenDate" class="text-danger">{{ errorOpenDate }}</small>

        <!-- CLOSE AT -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Close at</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <flat-pickr
              id="closeDate"
              v-model="closeDate"
              class="form-control"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i:s',
                enableSeconds: true,
                static: true,
              }"
            />
          </b-col>
        </b-row>
        <small v-if="errorCloseDate" class="text-danger">{{ errorCloseDate }}</small>

        <!-- EXPIRED DAYS -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Bonus expired days</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <b-form-input v-model="depositExpiredDays" type="number" :no-wheel="true" />
          </b-col>
        </b-row>
        <small v-if="errorExpiredDay" class="text-danger">{{ errorExpiredDay }}</small>

        <b-row>
          <b-col class="d-flex justify-content-end mt-4">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="loadingCreateBonus || loadingOfferCsv"
              @click="handleSubmitModal"
            >
              <Spinner v-if="loadingCreateBonus || loadingOfferCsv" />
              <template v-else>
                {{ modalType === 'add' ? ' Create Offer' : 'Save' }}
              </template>
            </b-button>
          </b-col>
        </b-row>
      </section>
    </b-modal>
    <b-modal
      ref="delete-offer"
      title="Delete Offer"
      centered
      cancel-variant="outline-secondary"
      @ok="handleSubmitDelete"
    >
      <div style="color: black">Are you sure you want to delete this offer?</div>
      <template #modal-ok>
        <span>Submit</span>
      </template>
    </b-modal>

    <b-modal
      ref="edit-profile"
      title="Edit Offer"
      centered
      hide-footer
      cancel-variant="outline-secondary"
      @hide="hideModalEdit"
    >
      <section style="color: black">
        <div style="font-size: 16px; font-weight: bold">
          List current user profile {{ type === 'list-user' ? `(${totalUser})` : '' }}
        </div>

        <b-row class="mt-2" style="max-height: 200px; overflow-y: scroll; margin-left: 4px">
          <b-col
            v-for="(i, ind) in dataOfferEditPage"
            :key="ind"
            md="6"
            style="display: flex; flex-direction: row; gap: 4px; justify-content: space-between; margin-bottom: 2px"
          >
            <b-badge
              style="
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis !important;
                white-space: nowrap;
                text-align: left;
              "
              pill
              variant="light-success"
            >
              {{ i.username ? i.username : i }}
            </b-badge>
            <div
              v-if="modalType === 'remove-profile'"
              style="
                background: red;
                color: white;
                padding-left: 4px;
                padding-right: 4px;
                border-radius: 5px;
                cursor: pointer;
                font-weight: bold;
              "
              @click="handleRemoveProfile(i)"
            >
              x
            </div>
          </b-col>
        </b-row>

        <div v-if="totalUser > 20 && type === 'list-user'" class="mx-2 mt-2">
          <b-row>
            <!-- Pagination -->
            <b-col cols="12" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="currentPageEdit"
                :total-rows="totalUser"
                :per-page="perPageEdit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <b-row v-if="editType === 'UserProfile'">
          <b-dropdown v-if="modalType === 'add-profile'" variant="link" no-caret boundary="viewport">
            <template #button-content>
              <div style="display: flex; align-items: center">
                <div style="margin-top: 6px; color: #000">Add new</div>
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 add-btn"
                  style="margin-left: 20px; margin-top: 10px"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                >
                  <template> + </template>
                </b-button>
              </div>
            </template>
            <template>
              <b-dropdown-item
                v-for="(i, ind) in clientProfileList"
                :key="ind"
                style="text-align: center"
                :disabled="disabledProfile(i)"
                @click="handleSelectProfile(i)"
              >
                {{ i }}
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </b-row>
        <b-row
          v-if="editType !== 'UserProfile' && modalType !== 'remove-profile'"
          style="margin-left: 2px; margin-top: 4px"
        >
          <b-col
            v-if="listSeletedUser.length > 0"
            cols="12"
            style="padding: 0 !important; margin-top: 10px; margin-bottom: 6px"
          >
            <div style="font-size: 16px; font-weight: bold; margin-bottom: 4px">List add more</div>
          </b-col>
          <b-col cols="11" style="display: flex; align-items: center; gap: 10px">
            <div v-if="listSeletedUser.length === 0" style="margin-top: -12px">Add new</div>
            <ChooseListUser @update-data="handleAddUserList" :listEditProfile="listEditProfile" />
          </b-col>
        </b-row>

        <b-row>
          <b-col class="d-flex justify-content-end mt-4" style="gap: 5px">
            <b-button
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="hideModalProfile"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="loadingCreateBonus"
              @click="handleAddMoreProfile"
            >
              <Spinner v-if="loadingCreateBonus" />
              <template v-else> Save </template>
            </b-button>
          </b-col>
        </b-row>
      </section>
    </b-modal>

    <b-modal
      @hide="hideModalListUser"
      ref="list-user"
      title="List user offer"
      centered
      cancel-variant="outline-secondary"
    >
      <template>
        <div style="color: black; font-weight: bold; margin-bottom: 10px">
          List {{ totalUser }} {{ totalUser > 1 ? 'users' : 'user' }}
        </div>
        <b-row style="max-height: 300px; min-height: 50px; overflow-y: scroll; margin-left: 4px">
          <b-col v-for="(i, ind) in listUserByOffer" :key="ind" md="6" style="margin-bottom: 2px">
            <b-badge
              style="
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis !important;
                white-space: nowrap;
                text-align: left;
              "
              pill
              variant="light-success"
            >
              {{ i.username }}
              <small v-if="typeof i.remain === 'number'" style="color: black">{{ `(${i.remain}$)` }}</small>
            </b-badge>
          </b-col>
        </b-row>
        <div v-if="totalUser > 20" class="mx-2">
          <b-row>
            <!-- Pagination -->
            <b-col cols="12" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalUser"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>

    <b-modal
      ref="direct-offer"
      title="Add direct Offer"
      centered
      cancel-variant="outline-secondary"
      @ok="handleSubmitAddDirect"
    >
      <template>
        <div style="color: black">
          <b-row class="mt-2">
            <b-col cols="5" class="d-flex align-items-center">
              <div class="font-weight-bold">Amount</div>
            </b-col>
            <b-col cols="7" class="d-flex" style="flex-direction: column; gap: 12px">
              <b-form-input
                v-model="bonusAmount"
                type="number"
                placeholder="$50"
                :no-wheel="true"
                :formatter="formatPrice"
              />
            </b-col>
          </b-row>
          <small v-if="errorBonusAmount" class="text-danger">{{ errorBonusAmount }}</small>
          <b-row class="mt-2">
            <b-col cols="5" class="d-flex" style="flex-direction: column">
              <div class="font-weight-bold" style="margin-top: 10px">Apply to User</div>
            </b-col>
            <b-col cols="7" class="d-flex" style="flex-direction: column">
              <ChooseListUser
                @update-data="handleAddUserList"
                @upload-file="handleGetDataUpload"
                :modalType="modalType"
              />
            </b-col>
          </b-row>
          <small v-if="errorChooseProfile" class="text-danger">{{ errorChooseProfile }}</small>
        </div>
      </template>
      <template #modal-ok>
        <span>Submit</span>
      </template>
    </b-modal>

    <!-- MODAL CREATE FULL CASH BETSLIP -->
    <b-modal
      ref="full-cash-betslip"
      :title="modalTypeFullCashBetslip === 'add' ? 'Add Full Cash Betslip Offer' : 'Edit Full Cash Betslip Offer'"
      centered
      hide-footer
      cancel-variant="outline-secondary"
      size="md"
      @hide="resetModalFullCash"
      @ok="handleSubmitModal"
    >
      <section style="color: black">
        <!-- OFFER NAME -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Offer name</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column">
            <b-form-input v-model="offerName" type="text" placeholder="Add name..." />
          </b-col>
        </b-row>
        <small v-if="errorName" class="text-danger">{{ errorName }}</small>

        <!-- DESCRIPTION -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Description</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column">
            <b-form-input v-model="description" type="text" placeholder="Add description..." />
          </b-col>
        </b-row>
        <small v-if="errorDescription" class="text-danger">{{ errorDescription }}</small>

        <!-- COMPETITIONID -->
        <b-row class="mt-2">
          <b-col cols="3">
            <div class="font-weight-bold">CompetitionId</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <b-form-input v-model="competitionId" placeholder="Enter competitionId..." type="text" />
          </b-col>
        </b-row>
        <small v-if="errorCompetitionId" class="text-danger">{{ errorCompetitionId }}</small>

        <!-- AMOUNT FULL BETSLIP -->
        <b-row class="mt-2">
          <b-col cols="3">
            <div class="font-weight-bold">Amount Full Betslip</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <b-form-input
              v-model="amountBetSlip"
              placeholder="Enter number betslip..."
              type="number"
              :no-wheel="true"
            />
          </b-col>
        </b-row>
        <small v-if="errorAmountBetSlip" class="text-danger">{{ errorAmountBetSlip }}</small>

        <!-- BONUS AMOUNT -->
        <b-row class="mt-2">
          <b-col cols="3">
            <div class="font-weight-bold">Bonus Amount</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <b-form-input
              v-model="bonus"
              type="number"
              placeholder="Enter bonus amount..."
              :no-wheel="true"
              :formatter="formatPrice"
            />
          </b-col>
        </b-row>
        <small v-if="errorBonus" class="text-danger">{{ errorBonus }}</small>

        <!-- OPEN AT -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Open at</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <flat-pickr
              v-model="openDate"
              class="form-control"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i:s',
                enableSeconds: true,
                static: true,
              }"
            />
          </b-col>
        </b-row>
        <small v-if="errorOpenDate" class="text-danger">{{ errorOpenDate }}</small>

        <!-- CLOSE AT -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Close at</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <flat-pickr
              id="closeDate"
              v-model="closeDate"
              class="form-control"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i:s',
                enableSeconds: true,
                static: true,
              }"
            />
          </b-col>
        </b-row>
        <small v-if="errorCloseDate" class="text-danger">{{ errorCloseDate }}</small>

        <!-- EXPIRED DAYS -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Bonus expired days</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <b-form-input v-model="depositExpiredDays" type="number" :no-wheel="true" />
          </b-col>
        </b-row>
        <small v-if="errorExpiredDay" class="text-danger">{{ errorExpiredDay }}</small>

        <!-- Type -->
        <b-row v-if="modalTypeFullCashBetslip === 'add'" class="mb-2 mt-1 d-flex align-items-start">
          <b-col cols="3" class="font-weight-bold">
            <span>List </span>
          </b-col>
          <b-col cols="9" style="display: flex; gap: 4px; align-items: center">
            <b-form-radio-group
              v-model="selectedList"
              :options="optionsList"
              name="radio-inline"
              style="display: flex"
            />
          </b-col>
        </b-row>

        <b-row v-if="modalTypeFullCashBetslip === 'add' && selectedList === 'UserProfile'" class="mt-2">
          <b-col cols="3" class="d-flex" style="flex-direction: column">
            <div class="font-weight-bold">Apply to Profile</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column">
            <ChooseListUserProfile
              :value="listProfile.value"
              :type-number="listProfile.id"
              :client-profile-list="clientProfileList"
              @update-data="handleUpdateTypeProfile"
            />
          </b-col>
        </b-row>
        <b-row v-if="modalTypeFullCashBetslip === 'add' && selectedList === 'UserId'" class="mt-2">
          <b-col cols="3" class="d-flex" style="flex-direction: column">
            <div class="font-weight-bold">Apply to User</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column">
            <ChooseListUser
              @update-data="handleAddUserList"
              @upload-file="handleGetDataUpload"
              :modalType="modalType"
            />
          </b-col>
        </b-row>
        <small v-if="errorChooseProfile" class="text-danger">{{ errorChooseProfile }}</small>

        <b-row>
          <b-col class="d-flex justify-content-end mt-4">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="loadingCreateBonus"
              @click="handleSubmitModalFullCashBetslip"
            >
              <Spinner v-if="loadingCreateBonus" />
              <template v-else>
                {{ modalTypeFullCashBetslip === 'add' ? ' Create Offer' : 'Save' }}
              </template>
            </b-button>
          </b-col>
        </b-row>
      </section>
    </b-modal>
  </section>
</template>

<script>
import {
  BBadge,
  BCol,
  BRow,
  BButton,
  BCard,
  BFormInput,
  BDropdown,
  BFormRadioGroup,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import { formatDateQuery, formatCurrencyBuiltInFunc, formatDate, formatDateSend } from '@/helpers/utils'
import Spinner from '@core/components/Spinner'
import vSelect from 'vue-select'
import useToastHandler from '@/services/toastHandler'
import flatPickr from 'vue-flatpickr-component'
import { subDays } from 'date-fns'
import ChooseListUserProfile from './ChooseListUserProfile'
import BonusCashTable from './BonusCashTable'
import ChooseListUser from './ChooseListUser'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BFormInput,
    BButton,
    Spinner,
    ChooseListUserProfile,
    ChooseListUser,
    BonusCashTable,
    vSelect,
    flatPickr,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BPagination,
  },
  data() {
    return {
      listProfile: { id: 1, value: '' },
      selectedProfileList: [],

      offerName: '',
      description: '',
      bonusLimit: null,
      customerProfile: null,
      openDate: new Date(),
      closeDate: new Date(),
      depositExpiredDays: null,
      bonusPercent: null,

      errorName: '',
      errorDescription: '',
      errorBonusPercent: '',
      errorBonusLimit: '',
      errorChooseProfile: '',
      errorOpenDate: '',
      errorCloseDate: '',
      errorExpiredDay: '',
      loadingAddCampaign: false,
      modalType: 'add',
      type: '',
      couponCampaignId: null,
      deleteId: null,

      disableDate: subDays(new Date(), 1),

      listEditProfile: [],
      newListProfile: [],
      listDeleteProfile: [],
      bonusOfferId: null,

      orderOptions: [
        { label: 'ASC', value: 'ASC' },
        { label: 'DESC', value: 'DESC' },
      ],
      fieldOptions: [
        { label: 'Create time', value: 'createdTime' },
        { label: 'Open at', value: 'openAt' },
        { label: 'Close at', value: 'closeAt' },
      ],
      statusOptions: [
        { label: 'Active', value: 1 },
        { label: 'Inactive', value: 0 },
      ],
      statusFilter: null,
      orderFilter: { label: 'DESC', value: 'DESC' },
      fieldFilter: { label: 'Create time', value: 'createdTime' },
      optionsType: [
        { text: 'Customer Profile', value: 'UserProfile' },
        { text: 'UserName', value: 'UserId' },
        { text: 'Direct', value: 'direct' },
      ],
      selectedType: 'UserProfile',

      listSeletedUser: [],
      editType: 'UserProfile',

      listUser: [],

      offerEdit: null,

      perPage: 20,
      currentPage: 1,

      csvData: [],
      formatCsvData: [],
      loadingOfferCsv: false,

      currentPageEdit: 1,
      perPageEdit: 20,
      total: null,
      dataOfferEditPage: [],

      bonusAmount: null,
      errorBonusAmount: '',
      offerDirectEditId: null,

      typeShow: null,
      modalTypeFullCashBetslip: 'add',
      competitionId: null,

      errorCompetitionId: '',
      amountBetSlip: null,
      errorAmountBetSlip: '',
      bonus: null,
      errorBonus: '',

      optionsList: [
        { text: 'Customer Profile', value: 'UserProfile' },
        { text: 'UserName', value: 'UserId' },
      ],
      selectedList: 'UserProfile',
    }
  },
  computed: {
    ...mapState('races/bonusCash', ['loadingCreateBonus', 'listUserByOffer', 'totalUser', 'bonusId']),
    ...mapState('races/setting', ['clientProfileList']),
  },
  watch: {
    offerName(val) {
      if (val) this.errorName = ''
    },
    description(val) {
      if (val) this.errorDescription = ''
    },
    selectedProfileList(val) {
      if (val.length > 0 && (this.selectedType === 'UserProfile' || this.selectedList === 'UserProfile'))
        this.errorChooseProfile = ''
    },
    listSeletedUser(val) {
      if (
        val.length > 0 &&
        (this.selectedType === 'UserId' || this.offerDirectEditId || this.selectedList === 'UserId')
      )
        this.errorChooseProfile = ''
    },
    bonusPercent(val) {
      if (val) this.errorBonusPercent = ''
    },
    bonusLimit(val) {
      if (val) this.errorBonusLimit = ''
    },
    depositExpiredDays(val) {
      if (val) this.errorExpiredDay = ''
    },
    currentPage(val) {
      if (this.typeShow === 'direct') {
        this.getUserBonusOfferDirect({
          bonusOfferId: this.bonusId,
          data: {
            page: val,
            limit: this.perPage,
          },
        })
      } else {
        this.getOffer({
          bonusOfferId: this.bonusId,
          data: {
            page: val,
            limit: this.perPage,
          },
        })
      }
    },
    currentPageEdit(val) {
      this.getOffer({
        bonusOfferId: this.bonusOfferId,
        data: {
          page: val,
          limit: this.perPageEdit,
        },
      })
    },
    listUserByOffer(val) {
      this.dataOfferEditPage = val
      if (this.listDeleteProfile.length > 0) {
        this.dataOfferEditPage = this.dataOfferEditPage.filter(
          x => !this.listDeleteProfile.some(i => i.appliableId === x.appliableId)
        )
      }
    },
    bonusAmount(val) {
      if (val) this.errorBonusAmount = ''
    },
    csvData(val) {
      if (val) this.errorChooseProfile = ''
    },
    selectedType(val) {
      if (val === 'direct') this.errorChooseProfile = ''
    },
    competitionId(val) {
      if (val) this.errorCompetitionId = ''
    },
    amountBetSlip(val) {
      if (val) this.errorAmountBetSlip = ''
    },
    bonus(val) {
      if (val) this.errorBonus = ''
    },
  },
  async mounted() {
    await this.getProfileType()
  },

  methods: {
    ...mapActions({
      createBonus: 'races/bonusCash/createBonus',
      addProfileUser: 'races/bonusCash/addProfileUser',
      removeProfileUser: 'races/bonusCash/removeProfileUser',
      updateOffer: 'races/bonusCash/updateOffer',
      deleteOffer: 'races/bonusCash/deleteOffer',
      getOffer: 'races/bonusCash/getOffer',
      createOfferDirect: 'races/bonusCash/createOfferDirect',
      getUserBonusOfferDirect: 'races/bonusCash/getUserBonusOfferDirect',
      getProfileType: 'races/setting/getProfileType',
    }),
    formatPrice(value) {
      if (value < 0) return null
      return value
    },
    handleUpdateTypeProfile(data) {
      this.selectedProfileList = data
    },
    handleAddUserList(data) {
      this.listSeletedUser = data
    },
    handleAddNewOffer() {
      this.resetModal()
      this.modalType = 'add'
      this.$refs.modal.show()
    },
    splitArray(array) {
      const result = []
      const length = array.length
      for (let i = 0; i < length; i += 200) {
        result.push(array.slice(i, i + 200))
      }
      return result
    },
    async handleCreateOfferDirect() {
      let id
      if (this.modalType === 'add' && !this.offerDirectEditId) {
        const data = {
          name: this.offerName,
          description: this.description,
          type: 'direct',
          openAt: this.formatDateSend(this.openDate),
          closeAt: this.formatDateSend(this.closeDate),
          bonusExpiredDays: Number(this.depositExpiredDays),
          data: {},
        }
        const res = await this.createBonus(data)
        id = res.data.bonusOfferId
      } else if (this.offerDirectEditId) {
        id = this.offerDirectEditId
      }

      if (!this.bonusAmount) {
        this.$refs.modal.hide()
        return
      }
      // Upload cvs file list user
      if (this.csvData.length > 0) {
        this.loadingOfferCsv = true
        if (this.formatCsvData.length > 0) {
          // if (res) {
          for (const item of this.formatCsvData) {
            const array = item.map(item => item.userId)

            await this.createOfferDirect({
              bonusOfferId: id,
              amount: this.bonusAmount,
              data: array,
            })
          }
          this.showToastSuccess('Success', 'Create offer successfully!')
          // }
          this.loadingOfferCsv = false
          this.$refs.modal.hide()
          return
        } else {
          const arr = this.csvData.map(i => i.userId)
          const res = await this.createOfferDirect({
            bonusOfferId: id,
            amount: this.bonusAmount,
            data: arr,
          })

          if (res) {
            this.showToastSuccess('Success', 'Create offer successfully!')
          }
          this.loadingOfferCsv = false
          this.$refs.modal.hide()
          this.csvData = []
          this.formatCsvData = []
          return
        }
      }
      // selected list user
      const listUsers = this.listSeletedUser.map(i => i.userId)
      const resDirect = await this.createOfferDirect({
        bonusOfferId: id,
        amount: this.bonusAmount,
        data: listUsers,
      })

      if (resDirect) {
        this.showToastSuccess('Success', 'Create offer successfully!')
      }
      this.$refs.modal.hide()
      this.resetModal()
    },
    async handleSubmitModal(event) {
      event.preventDefault()
      if (!this.offerName) this.errorName = 'Campaign name is required'
      if (!this.description) this.errorDescription = 'Description is required'
      if (typeof Number(this.bonusLimit) !== 'number' && this.selectedType !== 'direct')
        this.errorBonusLimit = 'Bonus limit is required'
      if (typeof Number(this.bonusPercent) !== 'number' && this.selectedType !== 'direct')
        this.errorBonusPercent = 'Bonus percent is required'
      if (!this.depositExpiredDays) this.errorExpiredDay = 'Expired day is required'
      if (this.selectedProfileList.length === 0 && this.modalType === 'add' && this.selectedType === 'UserProfile')
        this.errorChooseProfile = 'Customer profile is required'
      if (
        this.listSeletedUser.length === 0 &&
        this.csvData.length === 0 &&
        this.modalType === 'add' &&
        this.selectedType === 'UserId'
      )
        this.errorChooseProfile = 'User list is required'
      if (
        this.errorName ||
        this.errorDescription ||
        this.errorBonusLimit ||
        this.errorBonusPercent ||
        this.errorExpiredDay ||
        (this.errorChooseProfile && this.modalType === 'add')
      )
        return
      if (this.modalType === 'edit') {
        const res = await this.updateOffer({
          bonusOfferId: this.bonusOfferId,
          data: {
            name: this.offerName,
            description: this.description,
            type: this.offerEdit.type !== 'direct' ? 'deposit' : 'direct',
            openAt: this.formatDateSend(this.openDate),
            closeAt: this.formatDateSend(this.closeDate),
            bonusExpiredDays: Number(this.depositExpiredDays),
            data: {
              ...(this.offerEdit.type !== 'direct' && { percent: this.bonusPercent }),
              ...(this.offerEdit.type !== 'direct' && { bonusLimit: this.bonusLimit }),
            },
          },
        })
        if (res.data) {
          this.showToastSuccess('Success', 'Update offer successfully!')
        }
        this.$refs.modal.hide()
        this.resetModal()
      }
      if (this.modalType !== 'add') return
      if (this.selectedType === 'direct') {
        await this.handleCreateOfferDirect()
        return
      }

      // Upload cvs file list user
      if (this.csvData.length > 0) {
        this.loadingOfferCsv = true
        if (this.formatCsvData.length > 0) {
          const listUser = this.formatCsvData[0]
          const formatList = listUser.map(i => {
            return {
              appliableType: 'UserId',
              appliableId: i.userId,
            }
          })
          const data = {
            name: this.offerName,
            description: this.description,
            type: 'deposit',
            openAt: this.formatDateSend(this.openDate),
            closeAt: this.formatDateSend(this.closeDate),
            bonusExpiredDays: Number(this.depositExpiredDays),
            data: {
              percent: this.bonusPercent,
              bonusLimit: this.bonusLimit,
            },
            applies: formatList,
          }
          const res = await this.createBonus(data)
          if (res) {
            const id = res.data.bonusOfferId
            this.formatCsvData.shift()
            for (const item of this.formatCsvData) {
              const array = item.map(item => ({
                appliableType: 'UserId',
                appliableId: item.userId,
              }))

              await this.addProfileUser({
                bonusOfferId: id,
                data: array,
              })
            }
            this.showToastSuccess('Success', 'Create offer successfully!')
          }
          this.loadingOfferCsv = false
          this.$refs.modal.hide()
          return
        } else {
          const arr = this.csvData.map(i => {
            return {
              appliableType: 'UserId',
              appliableId: i.userId,
            }
          })
          const data = {
            name: this.offerName,
            description: this.description,
            type: 'deposit',
            openAt: this.formatDateSend(this.openDate),
            closeAt: this.formatDateSend(this.closeDate),
            bonusExpiredDays: Number(this.depositExpiredDays),
            data: {
              percent: this.bonusPercent,
              bonusLimit: this.bonusLimit,
            },
            applies: arr,
          }
          const res = await this.createBonus(data)
          if (res) {
            this.showToastSuccess('Success', 'Create offer successfully!')
          }
          this.loadingOfferCsv = false
          this.$refs.modal.hide()
          this.csvData = []
          this.formatCsvData = []
          return
        }
      }

      const listApplies = this.selectedProfileList.map(i => ({
        appliableType: 'UserProfile',
        appliableId: i === 'NOT RATED' ? 'NOT_RATED' : i,
      }))

      const listUsers = this.listSeletedUser.map(i => ({
        appliableType: 'UserId',
        appliableId: i.userId,
      }))

      const data = {
        name: this.offerName,
        description: this.description,
        type: 'deposit',
        openAt: this.formatDateSend(this.openDate),
        closeAt: this.formatDateSend(this.closeDate),
        bonusExpiredDays: Number(this.depositExpiredDays),
        data: {
          percent: this.bonusPercent,
          bonusLimit: this.bonusLimit,
        },
        applies: this.selectedType === 'UserProfile' ? listApplies : listUsers,
      }

      const res = await this.createBonus(data)
      if (res.data) {
        this.showToastSuccess('Success', 'Create offer successfully!')
      }
      this.$refs.modal.hide()
      this.resetModal()
    },

    hideModalListUser() {
      this.currentPage = 1
    },
    hideModal() {
      this.$refs.modal.hide()

      if (this.modalType === 'edit') this.resetModal()
    },
    hideModalEdit() {
      this.dataOfferEditPage = []
      this.listEditProfile = []
      this.newListProfile = []
    },
    resetModal() {
      this.offerName = ''
      this.depositExpiredDays = null
      this.customerProfile = null
      this.bonusLimit = null
      this.bonusPercent = null
      this.description = null
      this.openAt = new Date()
      this.closeAt = new Date()
      this.bonusExpiredDays = null
      this.listProfile.value = ''
      this.errorName = ''
      this.errorDescription = ''
      this.errorExpiredDay = ''
      this.errorBonusLimit = ''
      this.errorBonusPercent = ''
      this.errorChooseProfile = ''
      this.offerEdit = null
    },
    handleUpdateFullCashBetslip(item) {
      this.modalTypeFullCashBetslip = 'edit'
      this.offerName = item.name
      this.description = item.description
      this.competitionId = item.competitionId
      this.amountBetSlip = item.data.amountBetSlip
      this.bonus = item.data.bonus
      this.openDate = new Date(item.openAt)
      this.closeDate = new Date(item.closeAt)
      this.depositExpiredDays = item.bonusExpiredDays
      return this.$refs['full-cash-betslip'].show()
    },
    async handleUpdateOffer(data) {
      this.bonusOfferId = data.item.bonusOfferId
      if (data.item.type === 'full-cash-bet-slip' && data.type === 'edit') {
        this.handleUpdateFullCashBetslip(data.item)
        return
      }
      this.offerEdit = data.item
      this.modalType = data.type
      this.type = data.note

      if (data.note && data.type === 'add-profile' && data.note === 'new-profile') {
        this.editType = 'UserProfile'
        this.listEditProfile = []
        return this.$refs['edit-profile'].show()
      }

      if (data.note && data.type === 'add-profile' && data.note === 'new-user') {
        this.editType = 'UserId'
        this.listEditProfile = []
        return this.$refs['edit-profile'].show()
      }

      if (data.type === 'add-profile') {
        this.editType = data.item.bonusOfferAppliables[0].appliableType
        const list = data.item.bonusOfferAppliables
        if (data.note && data.note === 'list-user') {
          const res = await this.getOffer({
            bonusOfferId: data.item.bonusOfferId,
            data: {
              page: 1,
              limit: this.perPageEdit,
            },
          })
          if (res) {
            this.total = res.pagination.total
          }
          this.listEditProfile = res.data.map(i => {
            return {
              appliableId: i.appliableId,
              username: i.username,
            }
          })
          this.currentPageEdit = 1
          return this.$refs['edit-profile'].show()
        }
        this.listEditProfile = list.map(i => i.appliableId)
        this.dataOfferEditPage = this.listEditProfile
        return this.$refs['edit-profile'].show()
      }

      if (data.type === 'remove-profile') {
        this.editType = data.item.bonusOfferAppliables[0].appliableType
        const list = data.item.bonusOfferAppliables
        if (data.note && data.note === 'list-user') {
          const res = await this.getOffer({
            bonusOfferId: data.item.bonusOfferId,
            data: {
              page: 1,
              limit: this.perPageEdit,
            },
          })
          if (res) {
            this.total = res.pagination.total
          }
          this.listEditProfile = res.data.map(i => {
            return {
              appliableId: i.appliableId,
              username: i.username,
            }
          })
          this.currentPageEdit = 1
          return this.$refs['edit-profile'].show()
        }
        this.listEditProfile = list.map(i => i.appliableId)
        this.dataOfferEditPage = this.listEditProfile
        return this.$refs['edit-profile'].show()
      }
      if (data.type === 'edit') {
        const item = data.item
        this.offerName = item.name
        this.description = item.description
        this.bonusPercent = item.data.percent
        this.bonusLimit = item.data.bonusLimit
        this.openDate = new Date(item.openAt)
        this.closeDate = new Date(item.closeAt)
        this.depositExpiredDays = item.bonusExpiredDays
      }
      this.$refs.modal.show()
    },
    hideModalProfile() {
      this.listDeleteProfile = []
      this.newListProfile = []
      this.$refs['edit-profile'].hide()
    },
    showModal(item) {
      this.$refs['delete-offer'].show()
      this.deleteId = item.bonusOfferId
    },
    async handleSubmitDelete() {
      const res = await this.deleteOffer(this.deleteId)
      if (res) {
        this.showToastSuccess('Success', 'Delete this offer successfully!')
        this.$nextTick(() => {
          this.$refs['delete-offer'].hide()
        })
        this.deleteId = null
      } else {
        this.showToastError(false, 'Error delete this offer')
      }
    },
    onChangeOrder(selected) {
      this.orderFilter = selected
    },
    onChangeField(selected) {
      this.fieldFilter = selected
    },
    onChangeStatus(selected) {
      this.statusFilter = selected
    },
    async handleAddMoreProfile() {
      if (this.modalType === 'add-profile' && this.editType === 'UserProfile') {
        const list = this.newListProfile.map(item => ({
          appliableType: 'UserProfile',
          appliableId: item === 'NOT RATED' ? 'NOT_RATED' : item,
        }))

        const res = await this.addProfileUser({
          bonusOfferId: this.bonusOfferId,
          data: list,
        })
        if (res.data) {
          this.showToastSuccess('Success', 'Add user profile successfully!')
          this.$refs['edit-profile'].hide()
        }
        this.listEditProfile = []
        this.newListProfile = []
        this.listDeleteProfile = []
      }

      if (this.modalType === 'add-profile' && this.editType !== 'UserProfile') {
        const list = this.listSeletedUser.map(item => ({
          appliableType: 'UserId',
          appliableId: item.userId,
        }))

        const res = await this.addProfileUser({
          bonusOfferId: this.bonusOfferId,
          data: list,
        })
        if (res.data) {
          this.showToastSuccess('Success', 'Add list username successfully!')
          this.$refs['edit-profile'].hide()
        }
      }

      if (this.modalType === 'remove-profile' && this.editType === 'UserProfile') {
        const list = this.listDeleteProfile.map(item => ({
          appliableType: 'UserProfile',
          appliableId: item === 'NOT RATED' ? 'NOT_RATED' : item,
        }))

        const res = await this.removeProfileUser({
          bonusOfferId: this.bonusOfferId,
          data: list,
        })
        if (res.data) {
          this.showToastSuccess('Success', 'Remove user profile successfully!')
          this.$refs['edit-profile'].hide()
        }
        this.listEditProfile = []
        this.newListProfile = []
        this.listDeleteProfile = []
      }

      if (this.modalType === 'remove-profile' && this.editType !== 'UserProfile') {
        const list = this.listDeleteProfile.map(item => ({
          appliableType: 'UserId',
          appliableId: item.appliableId,
        }))

        const res = await this.removeProfileUser({
          bonusOfferId: this.bonusOfferId,
          data: list,
        })
        if (res.data) {
          this.showToastSuccess('Success', 'Remove user list successfully!')
          this.$refs['edit-profile'].hide()
        }
      }
    },
    handleSelectProfile(val) {
      this.listEditProfile.push(val)
      this.newListProfile.push(val)
      if (this.checkShowColumnProfile(this.offerEdit.bonusOfferAppliables).length > 0) return
      this.dataOfferEditPage.push(val)
    },
    disabledProfile(i) {
      const findItem = this.listEditProfile.some(x => x === i)
      return findItem
    },
    handleRemoveProfile(i) {
      this.listDeleteProfile.push(i)
      this.dataOfferEditPage = this.dataOfferEditPage.filter(x => x !== i)
    },
    handleShowUserList(data) {
      this.listUser = data
      this.$refs['list-user'].show()
    },
    handleGetDataUpload(data) {
      this.csvData = data

      if (data.length > 200) {
        const splitArrays = this.splitArray(data)
        this.formatCsvData = splitArrays
      } else {
        this.formatCsvData = []
      }
    },
    handleAddDirect(data) {
      this.offerDirectEditId = data.item.bonusOfferId
      this.$refs['direct-offer'].show()
    },
    async handleSubmitAddDirect(event) {
      event.preventDefault()
      if (!this.bonusAmount) this.errorBonusAmount = 'Amount is required'
      if (this.csvData.length === 0 && this.listSeletedUser.length === 0)
        this.errorChooseProfile = 'User list is required'
      if (this.errorBonusAmount || this.errorChooseProfile) return
      await this.handleCreateOfferDirect()
      this.$refs['direct-offer'].hide()
    },
    checkType(data) {
      this.typeShow = data
    },
    checkShowColumnProfile(list) {
      const arr = list.filter(i => i.appliableType === 'UserProfile')
      return arr
    },
    handleAddOfferFullCashBetslip() {
      this.modalTypeFullCashBetslip = 'add'
      this.$refs['full-cash-betslip'].show()
    },
    async handleSubmitModalFullCashBetslip(event) {
      event.preventDefault()
      if (!this.offerName) this.errorName = 'Campaign name is required'
      if (!this.description) this.errorDescription = 'Description is required'
      if (!this.competitionId) this.errorCompetitionId = 'CompetitionId is required'
      if (!this.amountBetSlip) this.errorAmountBetSlip = 'Full Betslip Amount is required'
      if (!this.bonus) this.errorBonus = 'Bonus Amount is required'
      if (!this.depositExpiredDays) this.errorExpiredDay = 'Expired day is required'
      if (
        this.selectedProfileList.length === 0 &&
        this.modalTypeFullCashBetslip === 'add' &&
        this.selectedList === 'UserProfile'
      )
        this.errorChooseProfile = 'Customer profile is required'
      if (
        this.listSeletedUser.length === 0 &&
        this.csvData.length === 0 &&
        this.modalTypeFullCashBetslip === 'add' &&
        this.selectedList === 'UserId'
      )
        this.errorChooseProfile = 'User list is required'
      if (
        this.errorName ||
        this.errorDescription ||
        this.errorCompetitionId ||
        this.errorAmountBetSlip ||
        this.errorBonus ||
        this.errorExpiredDay ||
        this.errorChooseProfile
      )
        return

      if (this.modalTypeFullCashBetslip === 'add') {
        // Upload cvs file list user
        if (this.csvData.length > 0) {
          this.loadingOfferCsv = true
          if (this.formatCsvData.length > 0) {
            const listUser = this.formatCsvData[0]
            const formatList = listUser.map(i => {
              return {
                appliableType: 'UserId',
                appliableId: i.userId,
              }
            })
            const data = {
              name: this.offerName,
              description: this.description,
              type: 'full-cash-bet-slip',
              openAt: this.formatDateSend(this.openDate),
              closeAt: this.formatDateSend(this.closeDate),
              bonusExpiredDays: Number(this.depositExpiredDays),
              data: {
                competitionId: this.competitionId,
                amountBetSlip: this.amountBetSlip,
                bonus: this.bonus,
              },
              applies: formatList,
            }
            const res = await this.createBonus(data)
            if (res) {
              const id = res.data.bonusOfferId
              this.formatCsvData.shift()
              for (const item of this.formatCsvData) {
                const array = item.map(item => ({
                  appliableType: 'UserId',
                  appliableId: item.userId,
                }))

                await this.addProfileUser({
                  bonusOfferId: id,
                  data: array,
                })
              }
              this.showToastSuccess('Success', 'Create offer successfully!')
            }
            this.loadingOfferCsv = false
            this.$refs['full-cash-betslip'].hide()
            return
          } else {
            const arr = this.csvData.map(i => {
              return {
                appliableType: 'UserId',
                appliableId: i.userId,
              }
            })
            const data = {
              name: this.offerName,
              description: this.description,
              type: 'full-cash-bet-slip',
              openAt: this.formatDateSend(this.openDate),
              closeAt: this.formatDateSend(this.closeDate),
              bonusExpiredDays: Number(this.depositExpiredDays),
              data: {
                competitionId: this.competitionId,
                amountBetSlip: this.amountBetSlip,
                bonus: this.bonus,
              },
              applies: arr,
            }
            const res = await this.createBonus(data)
            if (res) {
              this.showToastSuccess('Success', 'Create offer successfully!')
            }
            this.loadingOfferCsv = false
            this.$refs['full-cash-betslip'].hide()
            this.csvData = []
            this.formatCsvData = []
            return
          }
        }

        // LIST USER PROFILE
        const listApplies = this.selectedProfileList.map(i => ({
          appliableType: 'UserProfile',
          appliableId: i === 'NOT RATED' ? 'NOT_RATED' : i,
        }))

        const listUsers = this.listSeletedUser.map(i => ({
          appliableType: 'UserId',
          appliableId: i.userId,
        }))

        const data = {
          name: this.offerName,
          description: this.description,
          type: 'full-cash-bet-slip',
          openAt: this.formatDateSend(this.openDate),
          closeAt: this.formatDateSend(this.closeDate),
          bonusExpiredDays: Number(this.depositExpiredDays),
          data: {
            competitionId: this.competitionId,
            amountBetSlip: this.amountBetSlip,
            bonus: this.bonus,
          },
          applies: this.selectedList === 'UserProfile' ? listApplies : listUsers,
        }
        const res = await this.createBonus(data)
        if (res) {
          this.showToastSuccess('Success', 'Create offer successfully!')
          this.$refs['full-cash-betslip'].hide()
          this.resetModalFullCash()
        }
      }

      if (this.modalTypeFullCashBetslip === 'edit') {
        const res = await this.updateOffer({
          bonusOfferId: this.bonusOfferId,
          data: {
            name: this.offerName,
            description: this.description,
            type: 'full-cash-bet-slip',
            openAt: this.formatDateSend(this.openDate),
            closeAt: this.formatDateSend(this.closeDate),
            bonusExpiredDays: Number(this.depositExpiredDays),
            data: {
              competitionId: this.competitionId,
              amountBetSlip: this.amountBetSlip,
              bonus: this.bonus,
            },
          },
        })
        if (res.data) {
          this.showToastSuccess('Success', 'Update offer successfully!')
          this.$refs['full-cash-betslip'].hide()
          this.resetModalFullCash()
        }
      }
    },
    resetModalFullCash() {
      this.offerName = ''
      this.depositExpiredDays = null
      this.description = null
      this.competitionId = null
      this.amountBetSlip = null
      this.bonus = null
      this.openAt = new Date()
      this.closeAt = new Date()
      this.bonusExpiredDays = null
      this.errorName = ''
      this.errorDescription = ''
      this.errorCompetitionId = ''
      this.errorAmountBetSlip = ''
      this.errorBonus = ''
      this.errorExpiredDay = ''
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()
    return {
      showToastSuccess,
      showToastError,
      formatDateQuery,
      formatCurrencyBuiltInFunc,
      formatDate,
      formatDateSend,
    }
  },
}
</script>

<style lang="scss" scoped>
.btn-set {
  height: fit-content;
}
.add-btn {
  padding: 2px 4px;
}

.remove-btn {
  padding: 0px 4px 2px 4px;
  margin-left: 1px;
}

.delete-btn {
  color: red;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.v-select .vs__dropdown-toggle,
.v-select .vs__search {
  cursor: pointer;
}
@import '@core/scss/vue/libs/vue-select.scss';

.ml-14 {
  margin-left: 14px;
}
.dropdown-item.disabled {
  background: rgb(190, 189, 189);
}
</style>
