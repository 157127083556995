var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{ref:"refCampaignListTable",attrs:{"items":_vm.fetchCampaigns,"fields":_vm.tableColumns,"responsive":"","show-empty":"","primary-key":"campaign"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [(_vm.loadingListCampaign)?_c('div',{staticClass:"text-center text-dark my-2"},[_c('p',[_vm._v("Loading... Please wait")])]):(!_vm.loadingListCampaign && _vm.fetchCampaigns.length === 0)?_c('div',{staticClass:"text-center text-dark my-2"},[_vm._v(" No matching records found ")]):_vm._e()]},proxy:true},{key:"cell(depositAmount)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center font-weight-bold id-column"},[_c('div',{staticClass:"d-flex align-items-center font-weight-extrabold"},[_vm._v(" "+_vm._s(item.depositAmount !== 0 ? _vm.formatCurrencyBuiltInFunc(item.depositAmount) : '')+" ")])])]}},{key:"cell(depositBonusCouponAmount)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center font-weight-bold id-column"},[_c('div',{staticClass:"d-flex align-items-center font-weight-extrabold"},[_vm._v(" "+_vm._s(item.depositBonusCouponAmount !== 0 ?item.depositBonusCouponAmount : '')+" ")])])]}},{key:"cell(purchaseBetSlip)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center font-weight-bold id-column"},[_c('div',{staticClass:"d-flex align-items-center font-weight-extrabold"},[_vm._v(" "+_vm._s(item.purchaseBetSlip !== 0 ?item.purchaseBetSlip : '')+" ")])])]}},{key:"cell(purchaseBonusCouponAmount)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center font-weight-bold id-column"},[_c('div',{staticClass:"d-flex align-items-center font-weight-extrabold"},[_vm._v(" "+_vm._s(item.purchaseBonusCouponAmount !== 0 ?item.purchaseBonusCouponAmount : '')+" ")])])]}},{key:"cell(depositExpiredDays)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center font-weight-bold id-column"},[_c('div',{staticClass:"d-flex align-items-center font-weight-extrabold"},[_vm._v(" "+_vm._s(item.depositExpiredDays !== 0 ?item.depositExpiredDays : '')+" ")])])]}},{key:"cell(purchaseExpiredDays)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center font-weight-bold id-column"},[_c('div',{staticClass:"d-flex align-items-center font-weight-extrabold"},[_vm._v(" "+_vm._s(item.purchaseExpiredDays !== 0 ?item.purchaseExpiredDays : '')+" ")])])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center font-weight-bold id-column"},[_c('b-badge',{attrs:{"pill":"","variant":_vm.resolveCampaignVariant(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center font-weight-bold id-column"},[_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","boundary":"viewport"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleShowEditModal(item)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Edit")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.showModal(item)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }